import * as React from 'react';
import { Button, InputLabel, Grid2 as Grid } from '@mui/material';
import { NumberField, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import actions from '../../actions';

interface Props extends BaseDialogProps {
    readonly playerId: string;
    readonly stackables: Entities.PlayerStackableItem[];
}

interface State extends BaseDialogState {
    readonly itemId: string;
    readonly quantity: number;
}

export class PlayerGrantItemDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { itemId, quantity } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Item Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        value={itemId}
                        variant="outlined"
                        onChange={this.onItemIdChange}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Quantity</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        fullWidth={true}
                        label=""
                        value={quantity}
                        variant="outlined"
                        onChange={this.onQuantityChange}
                    />
                </Grid>
            </Grid>
        );

    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button color="secondary" variant="text" onClick={this.onSubmit}>
                Grant
            </Button>
        );
    }

    protected initState(): State {
        return {
            itemId: '',
            quantity: 1,
        };
    }

    private onItemIdChange = (itemId: string) => this.setState({ itemId });

    private onQuantityChange = (quantity: number) => this.setState({ quantity });

    private onSubmit = async () => {
        // TODO: pass onSubmit from outside?
        const { itemId, quantity } = this.state;
        this.props.onClose();
        await actions.player.grantItem(this.props.playerId, itemId, quantity);
    };
}
