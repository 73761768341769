import * as React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';
import { CasinoOutlined, DomainOutlined } from '@mui/icons-material';
import { Grid2 as Grid } from '@mui/material';

interface Props {
    readonly title: Entities.UserTitleData;
    readonly onClick: (title: Entities.UserTitleData) => any;
}

export class TitleCard extends React.PureComponent<Props> {
    render() {
        const { title } = this.props;
        const { id, name, imageUrl, organization } = title;

        return (
            <Card
                key={`title_${id}`}
                style={{
                    border: '1px solid rgb(217, 217, 217)',
                    boxShadow: 'rgb(224 224 224) 0px 0px 1px 1px',
                    borderRadius: 8,
                    backgroundColor: 'white',
                    width: '100%',
                    height: '100%',
                }}
            >
                <CardActionArea onClick={this.onCardClick}>
                    <CardContent style={{ padding: 10, display: 'flex' }}>
                        <Grid container={true} alignItems="center" spacing={2}>
                            <Grid size={12} style={{ display: 'grid', gridColumnGap: 10, gridAutoFlow: 'column', justifyContent: 'flex-start'}}>
                                {!imageUrl && (
                                    <CasinoOutlined style={{width: 50, height: 50, borderRadius: 5, color: 'rgba(0,0,0,0.8)'}}/>
                                )}
                                {imageUrl && (
                                    <CardMedia image={imageUrl} style={{width: 50, height: 50, borderRadius: 5}}/>
                                )}
                                <Grid container={true} alignItems="center" spacing={0}>
                                    <Grid size={12} style={{ display: 'grid', textAlign: 'left' }}>
                                        <Typography variant="h5" style={{ fontSize: '16px', fontWeight: 500 }}>
                                            {name}
                                        </Typography>
                                        <Typography variant="subtitle1" style={{ fontSize: '14px', color: 'rgba(0,0,0,0.54)' }}>
                                            {id}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid size={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <DomainOutlined style={{width: 20, height: 20, borderRadius: 5, color: 'grey'}} />
                                <Typography variant="subtitle2" style={{ color: '#0000008c', fontSize: 12, marginLeft: 2, lineHeight: '15px' }}>
                                    {organization}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }

    private onCardClick = () => {
        const { title, onClick } = this.props;
        onClick(title);
    };
}
