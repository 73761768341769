import * as _ from 'lodash';
import * as React from 'react';
import { UserRole, LoginType } from '../../enums';
import { Select, MenuItem, SelectChangeEvent, Grid2 as Grid, InputLabel } from '@mui/material';
import { TextField, Button } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';

interface Props extends BaseDialogProps {
    readonly loginUser: Entities.User;
    readonly user: Entities.BaseUser | null;
    readonly users: Entities.BaseUser[];
    readonly onCreate: (user: Entities.BaseUser) => void;
}

interface State extends BaseDialogState {
    readonly create: boolean;
    readonly username: string;
    readonly password: string;
    readonly role: UserRole;
    readonly userMap: Record<string, Entities.BaseUser>;
}

export class CreateUserDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { create, username, password } = this.state;
        const roles: UserRole[] = [UserRole.Admin, UserRole.Developer, UserRole.NoRole];

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>User Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        error={!this.validateUserName()}
                        value={username}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.updateUserName}
                        disabled={!create}
                    />
                </Grid>
                {this.enablePassword() && (
                    <>
                        <Grid size={12}>
                            <InputLabel>Password</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth={true}
                                error={!this.validatePassword()}
                                value={password}
                                label=""
                                type="password"
                                variant="outlined"
                                required={true}
                                onChange={this.updatePassword}
                            />
                        </Grid>
                    </>
                )}
                {!this.me() &&
                    <>
                        <Grid size={12}>
                            <InputLabel>Role</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <Select
                                value={this.state.role}
                                onChange={this.updateRole}
                                fullWidth={true}
                                variant="outlined"
                            >
                                {roles.map(value => (
                                    <MenuItem key={value} value={value}>
                                        {`${UserRole[value]}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </>
                }
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        const { create } = this.state;
        return (
            <Button text={create ? 'Create' : 'Update'} onClick={this.onCreate} variant="contained" color="secondary" disabled={!this.validate()} />
        );
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.user !== prevProps.user) {
            this.setState(this.initState());
        }
    }

    protected initState() {
        const { user, users } = this.props;
        return {
            create: !user,
            username: user ? user.username : '',
            password: '',
            role: user ? user.role : UserRole.NoRole,
            userMap: _.keyBy(users, u => u.username),
        };
    }

    private me(): boolean {
        const { loginUser, user } = this.props;
        return loginUser === user;
    }

    private enablePassword(): boolean {
        const { loginUser } = this.props;
        if (this.me() && loginUser.loginType === LoginType.Google) {
            return false;
        }
        return this.state.create || this.me();
    }

    private updateRole = (event: SelectChangeEvent<number>) =>
        this.setState({ role: event.target.value as number});

    private hasRole(): boolean {
        return this.props.loginUser.role === UserRole.Admin;
    }

    private updateUserName = (username: string) => this.setState({ username });

    private validate() {
        return this.hasRole() && this.validateUserName() && this.validatePassword() && this.checkUpdated();
    }

    private validateUserName(): boolean {
        const { user } = this.props;
        const { username, userMap } = this.state;
        if (!username) {
            return false;
        }
        return user ? user.username === username : !userMap[username];
    }

    private updatePassword = (password: string) => this.setState({ password });

    private validatePassword(): boolean {
        const { create, password } = this.state;
        return !create || password !== '';
    }

    private checkUpdated(): boolean {
        const { user } = this.props;
        const { username, role, password } = this.state;
        return !user || user.username !== username || user.role !== role || (this.enablePassword() && password !== '');
    }

    private onCreate = () => {
        const { username, role, password } = this.state;
        if (!this.validate()) {
            return;
        }
        const request: Entities.BaseUser = { username, role };
        if (this.enablePassword() && password) {
            request.password = password;
        }
        this.props.onCreate(request);
    };
}
