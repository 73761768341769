import { Request } from '../utils';

export const create = async (
    key: string,
    english: string,
    spanishMexican: string,
    spanishSpain: string,
    deutsch: string,
    french: string,
    italian: string,
    portugueseBrazil: string,
): Promise<void> => {
    await Request.post(`localization/create`, { key, english, spanishMexican, spanishSpain, deutsch, french, italian, portugueseBrazil });
};

export const update = async (
    key: string,
    english: string,
    spanishMexican: string,
    spanishSpain: string,
    deutsch: string,
    french: string,
    italian: string,
    portugueseBrazil: string,
): Promise<void> => {
    await Request.post(`localization/${key}/update`, { english, spanishMexican, spanishSpain, deutsch, french, italian, portugueseBrazil });
};

export const remove = async (segmentId: string): Promise<void> => {
    await Request.post(`segment/delete`, { segmentId });
};