import * as React from 'react';
import { TextField, Button } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { getVersionsViews } from '../../utils';

interface Props extends BaseDialogProps {
    readonly version: string;
    readonly subVersion?: string;
    readonly cloudCodeRevisions: Entities.CloudCode[];
    readonly titleDataVersions: Entities.TitleData[];
    readonly contentVersions: Entities.FileVersion[];
    readonly onCreate: (version: string, clientVersionOverride: Entities.ClientVersionOverride) => void;
}

interface State extends BaseDialogState {
    readonly subVersion: string;
    readonly cloudCodeRevision: number;
    readonly titleDataVersion: string;
    readonly contentVersion: string;
}

export class CreateClientVersionOverrideDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.version !== prevProps.version) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const { subVersion, cloudCodeRevisions, titleDataVersions, contentVersions } = this.props;
        const { cloudCodeRevision, titleDataVersion, contentVersion } = this.state;

        const versionsViews = getVersionsViews(cloudCodeRevisions, titleDataVersions, contentVersions, true);
        return (
            <Grid container={true} spacing={0}>
                {!subVersion && (
                    <>
                        <Grid size={12}>
                            <InputLabel>Sub Version</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth={true}
                                value={subVersion}
                                label=""
                                required={true}
                                onChange={this.updateSubVersion}
                                variant="outlined"
                            />
                        </Grid>
                    </>
                )}
                <Grid size={12}>
                    <InputLabel>Cloud Code</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={cloudCodeRevision}
                        onChange={this.handleCloudCodeRevisionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.cloudCodesViews}
                    </Select>
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="select-duration">Title Data</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={titleDataVersion}
                        onChange={this.handleTitleDataVersionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.titleDataVersionsViews}
                    </Select>
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="select-content">Content</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={contentVersion}
                        onChange={this.handleContentVersionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.contentVersionsViews}
                    </Select>
                </Grid>
            </Grid>
        );

    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="text" color="secondary" disabled={this.validate()} />
        );
    }

    protected initState(): State {
        const { subVersion } = this.props;
        return {
            subVersion: subVersion || '',
            cloudCodeRevision: -1,
            titleDataVersion: "",
            contentVersion: "",
        };
    }

    protected handleCloudCodeRevisionChange = (event: SelectChangeEvent<number>) => this.setState({ cloudCodeRevision: event.target.value as number });

    protected handleTitleDataVersionChange = (event: SelectChangeEvent) => this.setState({ titleDataVersion: event.target.value });

    protected handleContentVersionChange = (event: SelectChangeEvent) => this.setState({ contentVersion: event.target.value });

    private updateSubVersion = (subVersion: string) => this.setState({subVersion});

    private onSubmit = () => {
        const { version } = this.props;
        const { subVersion, cloudCodeRevision, titleDataVersion, contentVersion } = this.state;
        if (this.validate()) {
            return;
        }
        const clientVersionOverride: Entities.ClientVersionOverride = {
            version,
            subVersion,
            cloudCodeRevision,
            titleDataVersion,
            contentVersion,
        };
        this.props.onCreate(version, clientVersionOverride);
        this.onClose();
    };

    private validate = () => {
        const { subVersion, cloudCodeRevision, titleDataVersion, contentVersion } = this.state;
        return subVersion === '' && cloudCodeRevision === -1 && titleDataVersion === "" && contentVersion === "";
    };
}
