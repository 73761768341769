import * as React from 'react';
import { ActionRequest } from '../actions';
import { connect } from 'react-redux';
import { ContainerContext, mapProps } from './';
import { BaseContainer, BaseElement, Table } from '../components';
import { Grid2 as Grid } from '@mui/material';
import { formatDate } from '../utils';
import { Apple as AppleIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

interface State {
}

class Container extends BaseElement<Props, State> {
    protected renderContainer(): React.JSX.Element {
        return (
            <BaseContainer
                title = {'Apple Support'}
                TitleIcon={AppleIcon}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const columns = [
            { title: 'Transaction Id', field: 'transactionId'},
            { title: 'Bundle Id', field: 'bundleId'},
            { title: 'Product Id', field: 'productId'},
            { title: 'Purchase Date', field: 'purchaseDate'},
            { title: 'Quantity', field: 'quantity'},
            { title: 'Type', field: 'type'},
            { title: 'In App Ownership Type', field: 'inAppOwnershipType'},
            { title: 'Signed Date', field: 'signedDate'},
            { title: 'Environment', field: 'environment'},
        ];

        return (
            <Grid container={true} justifyContent="center" spacing={2}>
                <Grid size={12}>
                    <Table
                        columns={columns}
                        title={'Order ID Lookup'}
                        options={{
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: true,
                            draggable: false,
                        }}
                        ignoreSearchReadOnly={true}
                        data={this.searchOrderID}
                    />
                </Grid>
            </Grid>
        );
    };

    private searchOrderID = async (query: any) => {
        const { search } = query;
        if (search === '') {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const result = await ActionRequest.get(`costumerSupport/getAppleOrderID/${search}`);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.signedTransactions.map(
            (d: any) => ({
                transactionId: d.transactionId,
                originalTransactionId: d.originalTransactionId,
                bundleId: d.bundleId,
                productId: d.productId,
                purchaseDate: formatDate(d.purchaseDate),
                originalPurchaseDate: formatDate(d.originalPurchaseDate),
                quantity: d.quantity,
                type: d.type,
                inAppOwnershipType: d.inAppOwnershipType,
                signedDate: formatDate(d.signedDate),
                environment: d.environment,
            }));

        return {
            data,
            page: 0,
            totalCount: 0,
        };
    }
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
