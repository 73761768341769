import { CdnActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState } from '../reducers';

export const get = async (version?: string): Promise<void> => {
    version = version ? version : 'default';
    const filesData = await Request.get(`cdn/${version}`);
    dispatch({
        type: CdnActionType.Update,
        payload: filesData,
    });
};

export const createVersion = async (version: string, isDefault: boolean): Promise<void> => {
    const fileVersion = await Request.post(`cdn/createVersion`, {version, isDefault});
    const state = getState().cdn;
    dispatch({
        type: CdnActionType.Update,
        payload: {
            versions: [
                ...state.versions,
                fileVersion
            ],
            selectedVersion: isDefault ? fileVersion : state.selectedVersion,
            items: isDefault ? [] : state.items,
        },
    });
};

export const setVersionAsDefault = async (version: string): Promise<void> => {
    await Request.post(`cdn/${version}/setAsDefault`);
    const state = getState().cdn;
    dispatch({
        type: CdnActionType.Update,
        payload: {
            ...state,
            versions: state.versions.map( v => {
                v.isDefault = v.version === version;
                return v;
            }),
        },
    });
};

export const deleteVersion = async (version: string): Promise<void> => {
    await Request.post(`cdn/${version}/delete`);
    const state = getState().cdn;
    const versions = state.versions.filter(v => v.version !== version);
    dispatch({
        type: CdnActionType.Update,
        payload: {
            ...state,
            versions,
        },
    });
};

export const createItem = async (version: string, folder: string, file: any, fileName: string): Promise<void> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folder", folder);
    formData.append("fileName", fileName);
    const fileVersionItem = await Request.postForm(`cdn/${version}/createItem`, formData);
    const state = getState().cdn;
    dispatch({
        type: CdnActionType.Update,
        payload: {
            ...state,
            items: [...state.items, fileVersionItem],
        },
    });
};

export const deleteItem = async (version: string, name: string): Promise<void> => {
    await Request.post(`cdn/${version}/deleteItem`, { name });
    const state = getState().cdn;
    dispatch({
        type: CdnActionType.Update,
        payload: {
            ...state,
            items: state.items.filter(i => i.name !== name),
        },
    });
};

export const deleteMultipleItems = async (version: string, names: string[]): Promise<void> => {
    await Request.post(`cdn/${version}/deleteMultiple`, { names });
    const state = getState().cdn;
    dispatch({
        type: CdnActionType.Update,
        payload: {
            ...state,
            items: state.items.filter(i => !names.some(n => n === i.name)),
        },
    });
};
