import React from 'react';
import { CalendarOptions } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

interface Props extends CalendarOptions {
}

export class Calendar extends React.Component<Props> {
    render() {
        document.documentElement.style.setProperty('--fc-border-color', '#e0e0e0');

        const theme = createTheme({
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        '.fc-toolbar': {
                            marginBottom: 10,
                        },
                        '.fc-toolbar-title': {
                            fontSize: 20,
                        },
                        '.fc-theme-standard td': {
                            border: '1px solid #e0e0e0',
                        },
                        '.fc-day': {
                            backgroundColor: "white",
                        },
                        '.fc-day-other': {
                            backgroundColor: "#f7f7f7",
                        },
                        '.fc-daygrid-day-top': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        '.fc-daygrid-day-number': {
                            fontSize: '12px',
                            fontWeight: 600,
                        },
                        '.fc-col-header-cell': {
                            backgroundColor: '#f7f7f7',
                            borderBottom: 'none !important',
                        },
                        '.fc-col-header-cell-cushion': {
                            fontSize: '12px',
                            fontWeight: 500,
                            color: 'rgba(0,0,0,.38)',
                        },
                        '.fc-button-primary': {
                            textTransform: 'uppercase !important',
                            backgroundColor: 'transparent !important',
                            color: '#616161 !important',
                            padding: '0 16px !important',
                            height: '36px !important',
                            border: 'thin solid rgb(97, 97, 97) !important',
                            fontWeight: '500 !important',
                            letterSpacing: '.0892857143em',
                        },
                        '.fc-button-primary:disabled': {
                            backgroundColor: '#f7f7f7 !important',
                            display: 'none !important',
                        },
                        '.fc-prev-button': {
                            fontSize: '12px !important',
                            border: 'none !important',
                            height: 40,
                            width: 40,
                            padding: '0 !important',
                            borderRadius: '20px !important',
                            boxShadow: 'none !important',
                        },
                        '.fc-prev-button:focus': {
                            borderRadius: '20px !important',
                            boxShadow: 'none !important',
                            backgroundColor: '#dcdcdc !important',
                        },
                        '.fc-prev-button:hover': {
                            borderRadius: '20px !important',
                            boxShadow: 'none !important',
                            backgroundColor: '#dcdcdc !important',
                        },
                        '.fc-next-button': {
                            fontSize: '12px !important',
                            border: 'none !important',
                            height: 40,
                            width: 40,
                            padding: '0 !important',
                            borderRadius: '20px !important',
                            boxShadow: 'none !important',
                        },
                        '.fc-next-button:focus': {
                            borderRadius: '20px !important',
                            boxShadow: 'none !important',
                            backgroundColor: '#dcdcdc !important',
                        },
                        '.fc-next-button:hover': {
                            borderRadius: '20px !important',
                            boxShadow: 'none !important',
                            backgroundColor: '#dcdcdc !important',
                        },
                    },
                },
            },
        });
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    {...this.props}
                />
            </ThemeProvider>
        );
    }
}
