import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer, BaseElement,
    CreateNewLeaderBoardEventComponent,
    CreateNewRegularEventComponent,
} from '../components';
import { EventType, LeaderBoardType, ListEventFilterStateType } from '../enums';
import actions from '../actions';
import { connect } from 'react-redux';
import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly liveEventId: string;
    readonly details?: Entities.LiveEvent;
    readonly leaderBoardDefinition?: Entities.EntityLeaderBoard;
    readonly list?: Entities.LiveEvent[];
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        const { liveEventId } = this.props;
        await actions.liveEvent.getAndList(liveEventId, ListEventFilterStateType.ActiveAndComing);
    }

    protected renderContainer(): React.JSX.Element {
        const { details } = this.props;
        let headlineText = '';
        if (details) {
            switch (details.eventType) {
                case EventType.Regular:
                    headlineText = 'New Regular';
                    break;
                case EventType.LeaderBoard:
                    headlineText = 'New Leaderboard';
                    break;
                default:
                    break;
            }
        }

        const headlines = [
            {
                text: 'Live Event',
                to: `/liveEvent/all`,
            },
            {
                text: headlineText,
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                title = {"New Live Event"}
                TitleIcon = {CalendarTodayIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { details, list, leaderBoardDefinition } = this.props;
        if (!details || !list) {
            return;
        }
        switch (details.eventType) {
            case EventType.Regular:
                return (
                    <CreateNewRegularEventComponent
                        eventType={EventType.Regular}
                        type='Create'
                        liveEvents={list}
                        oldLiveEvent={details}
                        onCreate={this.onCreateRegularEvent}
                    />
                );
            case EventType.LeaderBoard:
                return (
                    <CreateNewLeaderBoardEventComponent
                        eventType={EventType.LeaderBoard}
                        type='Create'
                        liveEvents={list}
                        oldLiveEvent={details}
                        maxUsersPerInstance={leaderBoardDefinition ? leaderBoardDefinition.maxUsersPerInstance : 200}
                        onCreate={this.onCreateLeaderBoardEvent}
                    />
                );
            default:
                return;
        }
    }

    private onCreateRegularEvent = async (liveEvent: Entities.LiveEvent) => {
        await actions.liveEvent.createEventRegular(liveEvent);
        this.toLiveEvent();
    };

    private onCreateLeaderBoardEvent = async (liveEvent: Entities.LiveEvent, ranks: Entities.EntityLeaderBoardRank[], maxUsersPerInstance: number, leaderBoardType: LeaderBoardType,) => {
        await actions.liveEvent.createEventLeaderBoard(liveEvent, ranks, maxUsersPerInstance, leaderBoardType);
        this.toLiveEvent();
    };

    private toLiveEvent = () => this.toLink(`/liveEvent/all`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    liveEventId: '',
    details: state.liveEvent.details,
    leaderBoardDefinition: state.liveEvent.leaderBoardDefinition,
    list: state.liveEvent.list,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const liveEventId = params.liveEventId || '';
    return (<Container {...props} navigate={navigate} liveEventId={liveEventId}/>);
};
export default connect(mapStateToProps)(AppContainer);
