import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly onAdd: (name: string, username: string, password: string, url: string, jobName: string) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly username: string;
    readonly password: string;
    readonly url: string;
    readonly jobName: string;
}

export class AddJenkinsJobDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { name, username, password, url, jobName } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        required={true}
                        onChange={this.updateName}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Username</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={username}
                        label=""
                        required={true}
                        onChange={this.updateUsername}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Password</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={password}
                        label=""
                        required={true}
                        onChange={this.updatePassword}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Url</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={url}
                        label=""
                        required={true}
                        onChange={this.updateUrl}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Job Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={jobName}
                        label=""
                        required={true}
                        onChange={this.updateJobName}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validate()} />
        );
    }

    private onSubmit = () => {
        const { name, username, password, url, jobName } = this.state;
        if (!this.validate()) {
            return;
        }

        this.props.onAdd(name, username, password, url, jobName);
        this.onClose();
    };

    private updateName = (name: string) => this.setState({ name });
    private updateUsername = (username: string) => this.setState({ username });
    private updatePassword = (password: string) => this.setState({ password });
    private updateUrl = (url: string) => this.setState({ url });
    private updateJobName = (jobName: string) => this.setState({ jobName });

    private validate(): boolean {
        const { name, username, password, url, jobName } = this.state;
        return name !== '' && username !== '' && password !== '' && url !== '' && jobName !== '';
    }

    protected initState(): State {
        return {
            name: '',
            username: '',
            password: '',
            url: '',
            jobName: '',
        };
    }
}
