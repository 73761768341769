import { ClientVersionActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState } from '../reducers';

export const list = async (): Promise<void> => {
    const data = await Request.get(`clientVersion/list`);
    dispatch({
        type: ClientVersionActionType.Update,
        payload: data,
    });
};

export const create = async (clientVersion: Entities.ClientVersion): Promise<void> => {
    const clientVersionResp = await Request.post(`clientVersion/create`, clientVersion);
    const state = getState().clientVersion;
    const clientVersions = state.clientVersions ? [...state.clientVersions, clientVersionResp] : [clientVersionResp];
    dispatch({
        type: ClientVersionActionType.Update,
        payload: {
            clientVersions,
        },
    });
};

export const update = async (clientVersion: Entities.ClientVersion): Promise<void> => {
    if (clientVersion.titleDataVersion === null) {
        clientVersion.titleDataVersion = '';
    }
    if (clientVersion.contentVersion === null) {
        clientVersion.contentVersion = '';
    }
    const clientVersionResp = await Request.post(`clientVersion/update`, clientVersion);
    const state = getState().clientVersion;
    const clientVersions = state.clientVersions ? state.clientVersions.map(t => (t.version === clientVersionResp.version) ? clientVersion : t) : [clientVersionResp];
    dispatch({
        type: ClientVersionActionType.Update,
        payload: {
            clientVersions,
        },
    });
};

export const setLive = async (version: string): Promise<void> => {
    await Request.post(`clientVersion/setLive`, {version});
    const state = getState().clientVersion;
    const clientVersions = state.clientVersions ? state.clientVersions.map(t => {
        t.live = t.version === version;
        return t;
    }) : [];
    dispatch({
        type: ClientVersionActionType.Update,
        payload: {
            clientVersions,
        },
    });
};

export const remove = async (version: string): Promise<void> => {
    await Request.post(`clientVersion/remove`, {version});
    const state = getState().clientVersion;
    const clientVersions = state.clientVersions ? state.clientVersions.filter(t => t.version !== version) : [];
    dispatch({
        type: ClientVersionActionType.Update,
        payload: {
            clientVersions,
        },
    });
};

export const createOverride = async (version: string, clientVersionOverride: Entities.ClientVersionOverride): Promise<void> => {
    const resp = await Request.post(`clientVersion/${version}/createOverride`, clientVersionOverride);
    const state = getState().clientVersion;
    const clientVersionOverrides = state.clientVersionOverrides ? [...state.clientVersionOverrides, resp] : [resp];
    dispatch({
        type: ClientVersionActionType.Update,
        payload: {
            clientVersionOverrides,
        },
    });
};

export const updateOverride = async (version: string, clientVersionOverride: Entities.ClientVersionOverride): Promise<void> => {
    const resp = await Request.post(`clientVersion/${version}/updateOverride`, clientVersionOverride);
    const state = getState().clientVersion;
    const clientVersionOverrides = state.clientVersionOverrides ? state.clientVersionOverrides.map( c => c.version === version && c.subVersion === resp.subVersion ? resp : c) : [];
    dispatch({
        type: ClientVersionActionType.Update,
        payload: {
            clientVersionOverrides,
        },
    });
};

export const deleteOverride = async (version: string, subVersion: string): Promise<void> => {
    await Request.post(`clientVersion/${version}/deleteOverride`, {subVersion});
    const state = getState().clientVersion;
    const clientVersionOverrides = state.clientVersionOverrides ? state.clientVersionOverrides.filter( c => !(c.version === version && c.subVersion === subVersion)) : [];
    dispatch({
        type: ClientVersionActionType.Update,
        payload: {
            clientVersionOverrides,
        },
    });
};
