import * as React from 'react';
import { TextField, Button } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { getVersionsViews } from '../../utils';

interface Props extends BaseDialogProps {
    readonly selectedClientVersion: Entities.ClientVersion | null;
    readonly cloudCodeRevisions: Entities.CloudCode[];
    readonly titleDataVersions: Entities.TitleData[];
    readonly contentVersions: Entities.FileVersion[];
    readonly onUpdate: (clientVersion: Entities.ClientVersion) => void;
}

interface State extends BaseDialogState {
    readonly cloudCodeRevision: number;
    readonly titleDataVersion: string;
    readonly contentVersion: string;
    readonly forceUpdate: boolean;
}

export class UpdateClientVersionDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedClientVersion !== prevProps.selectedClientVersion) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const { selectedClientVersion, cloudCodeRevisions, titleDataVersions, contentVersions } = this.props;
        const { cloudCodeRevision, titleDataVersion, contentVersion } = this.state;

        const versionsViews = getVersionsViews(cloudCodeRevisions, titleDataVersions, contentVersions, true);
        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Version</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={selectedClientVersion ? selectedClientVersion.version : ''}
                        label=""
                        required={true}
                        disabled={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Cloud Code</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={cloudCodeRevision}
                        onChange={this.handleCloudCodeRevisionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.cloudCodesViews}
                    </Select>
                </Grid>
                <Grid size={12}>
                    <InputLabel>Title Data</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={titleDataVersion}
                        onChange={this.handleTitleDataVersionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.titleDataVersionsViews}
                    </Select>
                </Grid>
                <Grid size={12}>
                    <InputLabel>Content</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={contentVersion}
                        onChange={this.handleContentVersionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.contentVersionsViews}
                    </Select>
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onUpdate} variant="text" color="secondary" />
        );
    }

    protected initState(): State {
        const { selectedClientVersion } = this.props;
        if (!selectedClientVersion) {
            return {
                cloudCodeRevision: -1,
                titleDataVersion: "",
                contentVersion: "",
                forceUpdate: false,
            };
        }

        return {
            ...selectedClientVersion,
            cloudCodeRevision: selectedClientVersion.cloudCodeRevision || -1,
            titleDataVersion: selectedClientVersion.titleDataVersion || '',
            contentVersion: selectedClientVersion.contentVersion || '',
            forceUpdate: selectedClientVersion.forceUpdate || false,
        };
    }

    protected handleCloudCodeRevisionChange = (event: SelectChangeEvent<number>) => this.setState({ cloudCodeRevision: event.target.value as number });

    protected handleTitleDataVersionChange = (event: SelectChangeEvent) => this.setState({ titleDataVersion: event.target.value });

    protected handleContentVersionChange = (event: SelectChangeEvent) => this.setState({ contentVersion: event.target.value });

    private onUpdate = () => {
        const { selectedClientVersion } = this.props;
        if (selectedClientVersion == null) {
            return;
        }

        const { cloudCodeRevision, titleDataVersion, contentVersion, forceUpdate } = this.state;
        const clientVersion: Entities.ClientVersion = {
            version: selectedClientVersion.version,
            live: selectedClientVersion.live,
            cloudCodeRevision,
            titleDataVersion,
            contentVersion,
            forceUpdate,
            updated: 0,
        };
        this.props.onUpdate(clientVersion);
        this.onClose();
    };
}
