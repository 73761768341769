import * as React from 'react';
import { default as moment, Moment } from 'moment';
import { DurationMenuType, getDurationMenus, Menu } from '../../utils';
import { Button } from '../common';
import { SeasonTiers } from './SeasonTiers';
import {
    Grid2 as Grid,
    InputLabel,
    Select,
    Step as MaterialStep,
    StepButton,
    Stepper,
    Switch,
    Typography,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { MobileDatePicker as DatePicker, MobileTimePicker as TimePicker } from '@mui/x-date-pickers';

export interface CreateSeasonProps {
    readonly type: 'Create' | 'Edit';
    readonly oldSeason?: Entities.Season;
}

type Step = 'Detail' | 'Tiers' | 'Rewards' | 'Confirm';
export interface CreateSeasonState {
    readonly step: Step;
    readonly name: string;
    readonly start: Moment;
    readonly end: Moment;
    readonly duration: number;
    readonly timesMenus: Record<DurationMenuType, Menu>;
    readonly metadata: string;
    readonly developerOnly: boolean;
    readonly tiers: Entities.SeasonTier[];
    readonly useDuration: boolean;
    readonly maxAmountPerReport: number;
}

export class BaseCreateSeasonComponent<P extends CreateSeasonProps = CreateSeasonProps, S extends CreateSeasonState = CreateSeasonState> extends React.Component<P, S> {
    componentDidMount() {
        this.updateFromOld();
    }

    componentDidUpdate(prevProps: CreateSeasonProps) {
        if (this.props.oldSeason === prevProps.oldSeason) {
            return;
        }
        this.updateFromOld();
    }

    render() {
        const { step } = this.state;

        let detailContent: React.JSX.Element = <></>;
        let detailButtons: React.JSX.Element = <></>;

        let activeStep = 0;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Tiers':
                activeStep = 1;
                detailContent = this.renderTiersContent();
                detailButtons = this.renderTiersButtons();
                break;
            case 'Confirm':
                activeStep = 2;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
        }

        return (
            <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                <Grid size={12}>
                    <Stepper alternativeLabel={true} nonLinear={true} activeStep={activeStep}>
                        <MaterialStep key={'Details'} completed={activeStep > 0}>
                            <StepButton onClick={this.onDetailStepSet} >
                                {'Details'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Tiers'} completed={activeStep > 1}>
                            <StepButton onClick={this.onTiersStepSet} >
                                {'Tiers'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Confirm'} completed={activeStep > 2}>
                            <StepButton onClick={this.onConfirmStepSet} >
                                {'Confirm'}
                            </StepButton>
                        </MaterialStep>
                    </Stepper>
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    {detailContent}
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <div style={{
                        display: 'grid',
                        justifyContent: 'flex-end',
                        gridAutoFlow: 'column',
                        gridColumnGap: 10,
                    }}>
                        {detailButtons}
                    </div>
                </Grid>
            </Grid>
        );
    }

    protected updateFromOld() {
    }

    protected renderDetailContent(): React.JSX.Element {
        return <></>;
    }

    protected renderDetailButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onTiersStepSet} variant="contained"/>
            </>
        );
    }

    protected renderTiersContent = () => {
        const { tiers } = this.state;
        return (
            <SeasonTiers
                tiers={tiers}
                readOnly={false}
                onUpdate={this.onUpdateTiers}
            />
        );
    }

    protected renderTiersButtons = () => {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary" />
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained" />
            </>
        );
    };

    protected renderConfirmContent(): React.JSX.Element {
        return <></>;
    }

    protected renderConfirmButtons = () => {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onTiersStepSet} variant="contained" color="secondary" />
                <Button text="Confirm" onClick={this.onCreate} variant="contained" />
            </>
        );
    };

    protected onCreate = () => {
    }

    protected initState(): CreateSeasonState {
        const timesMenus = getDurationMenus();
        return {
            step: 'Detail',
            name: '',
            start: moment.parseZone(),
            end: moment.parseZone().add(1, 'month'),
            duration: timesMenus['1month'].value,
            timesMenus,
            metadata: '',
            developerOnly: false,
            tiers: [],
            useDuration: true,
            maxAmountPerReport: 0,
        };
    }

    protected renderTimerCreation = () => {
        const { type } = this.props;
        const { start, end, duration, timesMenus, useDuration } = this.state;
        const durationViews: React.JSX.Element[] = [];
        Object.keys(timesMenus).forEach((key: string) => {
            const durationMenuType: DurationMenuType = key as DurationMenuType;
            const {title, value} = timesMenus[durationMenuType];
            durationViews.push(
                <MenuItem key={key} value={value}>
                    {title}
                </MenuItem>
            );
        });
        return (
            <>
                <Grid size={12}>
                    <InputLabel>Start Date</InputLabel>
                </Grid>
                <Grid size={12}>
                    <DatePicker
                        label=""
                        value={start}
                        onChange={this.handleStartDateChange}
                        disablePast={type !== 'Edit'}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Start Time</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TimePicker
                        label=""
                        value={start}
                        onChange={this.handleStartDateChange}
                        ampm={false}
                    />
                </Grid>
                {useDuration && (
                    <>
                        <Grid size={12}>
                            <InputLabel>Duration</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <Select
                                value={duration}
                                onChange={this.onUpdateDuration}
                                style={{ width: '100%', backgroundColor: 'white' }}
                                variant={'outlined'}
                            >
                                {durationViews}
                            </Select>
                        </Grid>
                    </>
                )}
                {!useDuration && (
                    <>
                        <Grid size={12}>
                            <InputLabel>End Date</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <DatePicker
                                label=""
                                value={end}
                                onChange={this.handleEndDateChange}
                                disablePast={type !== 'Edit'}
                            />
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>End Time</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TimePicker
                                label=""
                                value={end}
                                onChange={this.handleEndDateChange}
                                ampm={false}
                            />
                        </Grid>
                    </>
                )}
                <Grid size={12}>
                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Set Time</Typography>
                        <Switch
                            checked={useDuration}
                            onChange={this.changeEndTimeMode}
                            color="primary"
                            name="checkedLeaderBoardView"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Duration</Typography>
                    </div>
                </Grid>
            </>
        );
    };

    protected onUpdateName = (name: string) => this.setState({ name });

    protected onUpdateTiers = (tiers: Entities.SeasonTier[]) => this.setState({ tiers });

    protected onDetailStepSet = () => this.setState({ step: 'Detail'});

    protected onTiersStepSet = () => this.setState({ step: 'Tiers'});

    protected onRewardsStepSet = () => this.setState({ step: 'Rewards'});

    protected onConfirmStepSet = () => this.setState({ step: 'Confirm'});

    protected onUpdateDuration = (event: SelectChangeEvent<S['duration']>) => {
        this.setState({...this.state, duration: event.target.value});
    };

    protected handleStartDateChange = (value: Moment | null) => {
        if (!value) {
            return;
        }
        this.setState({ start: value });
    }

    protected handleEndDateChange = (value: Moment | null) => {
        if (!value) {
            return;
        }
        this.setState({ end: value });
    }

    protected changeEndTimeMode = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ useDuration: event.target.checked });

    protected handleChangeDeveloperOnly = (developerOnly: boolean) => this.setState({developerOnly});

    protected handleChangeMetadata = (metadata: string) => this.setState({ metadata });

    protected handleMaxAmountPerReport = (maxAmountPerReport: number) => this.setState({ maxAmountPerReport });
}
