import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Button } from '../common';
import * as React from 'react';
import { PlayerGroupState } from '../../enums';

interface Props extends BaseDialogProps {
    readonly state: PlayerGroupState;
    readonly onUpdate: (state: PlayerGroupState) => void;
}

interface State extends BaseDialogState {
    readonly state: PlayerGroupState;
}

export class ChangeStateGroupPlayerDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidMount() {
        const { state } = this.props;
        this.setState({ state });
    }

    protected renderContent(): React.JSX.Element {
        const {
            state,
        } = this.state;

        const playerGroupStateKeys = Object.keys(PlayerGroupState)
            .filter((key) => Number.isNaN(+key))
            .map((key: string, value: number) => ({
                key,
                value,
            }));

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>State</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        labelId={`select-player-group-sate`}
                        value={state}
                        variant={'outlined'}
                        onChange={this.handleChangeState}
                        disableUnderline={true}
                        fullWidth={true}
                    >
                        <MenuItem key={'Member'} value={PlayerGroupState.Member}>
                            Member
                        </MenuItem>
                        <MenuItem key={'Admin'} value={PlayerGroupState.Admin}>
                            Admin
                        </MenuItem>
                        <MenuItem key={'SuperAdmin'} value={PlayerGroupState.SuperAdmin}>
                            SuperAdmin
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Change" onClick={this.onUpdate} variant="text" color="secondary" disabled={!this.validate()} />
        );
    }

    protected initState(): State {
        return {
            state: PlayerGroupState.Member,
        };
    }

    private handleChangeState = (event: SelectChangeEvent<number>) => {
        const state = event.target.value as PlayerGroupState;
        this.setState({ state });
    };

    private onUpdate = () => {
        const { state } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onUpdate(state);
        this.onClose();
    };

    private validate(): boolean {
        const { state } = this.state;
        return this.props.state !== state;
    }
}
