import * as React from 'react';
import { formatDate, isActive, isComing, isEnded, Link, truncateString } from '../utils';
import { WarningDialog, BaseEconomyContainer, Table, BaseElement, HeaderButton, EconomyStoreFilter } from '../components';
import { ContainerContext, mapProps } from './index';
import actions, { ActionRequest } from '../actions';
import { connect } from 'react-redux';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    LibraryAddOutlined,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { StoreItemStatusFilter, UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { Chip, Grid2 as Grid } from '@mui/material';
import { StyledChip } from './LiveEvents';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly segments: Entities.Segment[];
}

type DialogType = 'Delete';
interface State {
    readonly statusFilter: StoreItemStatusFilter;
    readonly openDialog: DialogType | null;
    readonly selectedItem: Entities.EconomyStoreItem | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        statusFilter: StoreItemStatusFilter.All,
        openDialog: null,
        selectedItem: null,
    };

    async componentDidMount() {
        await actions.segment.listAll();
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New Item', icon: AddOutlined, onClick: this.openCreateItem},
        ];
        return (
            <BaseEconomyContainer
                {...this.props}
                title = {"Items"}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseEconomyContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { loading } = this.props;
        const { statusFilter } = this.state;
        const columns = [
            { title: 'Details', field: 'details'},
        ];

        const onSearch = () => {
            // @ts-ignore
            tableRef.current.onSearchChangeDebounce();
        };
        const onRowClick = (event: any, rowData: any) => this.toLink(`/economy/store/${rowData.id}/details`);

        const onResetStatusFilter = () => {
            this.setState({ statusFilter: StoreItemStatusFilter.All });
            onSearch();
        };

        const onFilter = (status: StoreItemStatusFilter) => {
            this.setState({ statusFilter: status });
            onSearch();
        };

        const tableRef = React.createRef();

        const showChips = statusFilter !== StoreItemStatusFilter.All;
        const title = (
            <Grid container={true} spacing={1}>
                <Grid size={12}>
                    <EconomyStoreFilter
                        statusFilter={statusFilter}
                        onFilter={onFilter}
                    />
                </Grid>
                {showChips &&
                    <Grid size={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gridColumnGap: 5, gridGap: 5}} >
                            {statusFilter === StoreItemStatusFilter.Active && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "In Progress"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {statusFilter === StoreItemStatusFilter.Coming && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "Scheduled"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                            {statusFilter === StoreItemStatusFilter.Completed && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Status: "Completed"'
                                    onDelete={onResetStatusFilter}
                                />
                            )}
                        </div>
                    </Grid>
                }
            </Grid>
        );

        return (
            <Table
                tableRef={tableRef}
                title={title}
                data = {this.getStoreItems}
                isLoading = {loading}
                columns={columns}
                onRowClick={onRowClick}
                options={{
                    selection: false,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1
                }}
                actions = {[
                    rowData => ({
                        icon: ModeEditOutlineOutlined,
                        hidden: isEnded(rowData.storeItem.endTimestamp),
                        tooltip: 'Edit',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.edit(rowData.storeItem)
                    }),
                    rowData => ({
                        icon: LibraryAddOutlined,
                        tooltip: 'Duplicate',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.duplicate(rowData.storeItem)
                    }),
                    rowData => ({
                        icon: DeleteOutlineOutlined,
                        tooltip: 'Delete',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.delete(rowData.storeItem)
                    }),
                ]}
            />
        );
    }

    private getStoreItems = async (query: any) => {
        const { statusFilter } = this.state;
        const { search, page, pageSize } = query;

        const list = await ActionRequest.get<Entities.ListEconomyStoreItemResponse>(`economy/store/listByPage`, {
            name: search,
            statusFilter,
            page: page + 1,
            perPage: pageSize,
        });


        if (!list) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const storeItems = list.items
            .sort((e1, e2) => {
                const e1Active = isActive(e1.startTimestamp, e1.endTimestamp);
                const e2Active = isActive(e2.startTimestamp, e2.endTimestamp);
                const e1Coming = isComing(e1.startTimestamp, e1.endTimestamp);
                const e2Coming = isComing(e2.startTimestamp, e2.endTimestamp);
                if (e1Active && e2Coming) {
                    return -1;
                } else if (e2Active && e1Coming) {
                    return 1;
                } else if ((e1Active && !e2Active) || (e1Coming && !e2Coming)) {
                    return -1;
                } else if (!e1Active && e2Active || (!e1Coming && e2Coming)) {
                    return 1;
                }
                return e2.startTimestamp - e1.startTimestamp;
            });

        const data = storeItems.map(item => {
                return {
                    details: this.getDetails(item),
                    id: item.id,
                    storeItem: item,
                };
            }
        );
        return {
            data,
            page: list.page - 1,
            totalCount: list.totalCount,
        };
    };

    private getDetails = (storeItem: Entities.EconomyStoreItem) => {
        const { segments } = this.props;
        const segmentElements: React.JSX.Element[] = [];
        storeItem.segmentIds.forEach(segmentId => {
            const segment = segments.find(s => s.id === segmentId);
            if (!segment) {
                return;
            }
            segmentElements.push(
                <Chip
                    color={'secondary'}
                    label={segment.name}
                    style={{ backgroundColor: `${segment.color}`, color: 'white', margin: '0px 4px' }}
                />
            );
        });
        const to = `/economy/store/${storeItem.id}/details`;
        return (
            <div>
                <div>
                    {segmentElements}
                </div>
                <div style={{ padding: '5px 0px ' }}>
                    <Link to={to}>
                        {storeItem.displayName !== '' ? truncateString(storeItem.displayName) : storeItem.id}
                    </Link>
                    {storeItem.startTimestamp && storeItem.endTimestamp && (
                        <>
                            <div>
                                <div style={{ fontWeight: 500 }}>Status</div>
                                {this.getStatus(storeItem)}
                            </div>
                            <div>
                                <div style={{ fontWeight: 500 }}>Start</div>
                                {formatDate(storeItem.startTimestamp)}
                            </div>
                            <div>
                                <div style={{ fontWeight: 500 }}>Last Edited</div>
                                {formatDate(storeItem.endTimestamp)}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    private getStatus = (storeItem: Entities.EconomyStoreItem) => {
        if (!storeItem.startTimestamp || !storeItem.endTimestamp) {
            return <></>;
        }
        if (isActive(storeItem.startTimestamp, storeItem.endTimestamp)) {
            return <div style={{color: 'green', fontWeight: 500}}>In Progress</div>;
        }
        if (isEnded(storeItem.endTimestamp)) {
            return <div style={{color: 'orange', fontWeight: 500}}>Completed</div>;
        }
        return <div style={{color: 'grey', fontWeight: 500}}>Scheduled</div>;
    };

    private openCreateItem = () => this.toLink(`/economy/store/new`);

    private edit = (item: Entities.EconomyItem) => this.toLink(`/economy/store/${item.id}/edit`);

    private duplicate = (item: Entities.EconomyItem) => this.toLink(`/economy/store/${item.id}/duplicate`);

    private delete = (item: Entities.EconomyStoreItem) => this.setState({openDialog: 'Delete', selectedItem: item});
    private renderDeleteDialog = () => {
        const { openDialog, selectedItem } = this.state;
        if (!selectedItem) {
            return <></>;
        }
        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={`Delete Store Item ${selectedItem.id}`}
                content="This will permanently delete the economy store item."
                onClose={this.onCloseDialog}
                onSubmit={this.onDelete}
                maxWidth={'xs'}
            />
        );
    };
    private onDelete = async () => {
        const { selectedItem } = this.state;
        this.onCloseDialog();
        if (!selectedItem) {
            return;
        }
        await actions.economy.deleteStoreItem(selectedItem.id);
    };

    private onCloseDialog = () => this.setState({ openDialog: null, selectedItem: null});
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    segments: state.segment.segments,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate} />);
};
export default connect(mapStateToProps)(AppContainer);
