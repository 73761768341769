import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { SegmentRules } from './SegmentRules';
import { Grid2 as Grid, InputLabel, SelectChangeEvent } from '@mui/material';
import { colorMenus, getColorTitleByIndex, renderColorSelect } from '../../utils';

interface Props extends BaseDialogProps {
    readonly onCreate: (name: string, color: string, rules: Entities.SegmentRule[]) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly color: number;
    readonly rules: Entities.SegmentRule[];
}

export class CreateSegmentDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { name, color } = this.state;
        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.onUpdateName}
                        disabled={false}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Color</InputLabel>
                </Grid>
                <Grid size={12}>
                    {renderColorSelect(this.handleColorChange, color)}
                </Grid>
                <Grid size={12}>
                    <SegmentRules
                        onUpdateRules={this.onUpdateRules}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validate()}/>
        );
    }

    protected initState(): State {
        return {
            name: '',
            color: colorMenus.Grey.value,
            rules: [],
        };
    }

    private validate(): boolean {
        const { name, rules } = this.state;
        if (!name || name === '') {
            return false;
        }
        return rules.length !== 0;
    }

    private onUpdateName = (name: string) => this.setState({ name });

    private onUpdateRules = (rules: Entities.SegmentRule[]) => {
        this.setState({rules});
    }

    protected handleColorChange = (event: SelectChangeEvent<number>) => this.setState({ color: event.target.value as number });

    private onSubmit = () => {
        const { name, rules } = this.state;
        const color = getColorTitleByIndex(this.state.color);

        this.props.onCreate(name, color, rules);
        this.onClose();
    };
}
