import * as React from 'react';
import { BaseDialog, BaseDialogProps, BaseDialogState } from './BaseDialog';
import { Button } from '../input';
import { Grid2 as Grid } from '@mui/material';
import { Warning } from '@mui/icons-material';

interface WarningDialogProps extends BaseDialogProps {
    readonly content: string;
    readonly onSubmit: () => void;
    readonly submitButtonText?: string;
    readonly disabledSubmitButton?: boolean;
}

export class WarningDialog<P extends WarningDialogProps = WarningDialogProps, S extends BaseDialogState = BaseDialogState> extends BaseDialog<P, S> {
    protected renderContent(): React.JSX.Element {
        const { content } = this.props;
        return (
            <Grid container={true} spacing={1} style={{
                padding: 10,
                fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
                fontSize: '0.8755rem',
                fontWeight: 400,
                lineHeight: '1.25rem',
                letterSpacing: '-0.006rem',
            }}>
                <Grid size={1}>
                    <Warning style={{ color: '#ff9800' }} />
                </Grid>
                <Grid size={11}>
                    {content}
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        const { submitButtonText, disabledSubmitButton } = this.props;
        return (
            <Button text={submitButtonText || 'Confirm'} onClick={this.onSubmit} variant="text" color="secondary" disabled={disabledSubmitButton}/>
        );
    }

    private onSubmit = () => {
        this.props.onSubmit();
        this.onClose();
    }
}
