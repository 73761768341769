import * as React from 'react';
import { TextField, Button } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { getVersionsViews } from '../../utils';

interface Props extends BaseDialogProps {
    readonly clientVersions: Entities.ClientVersion[];
    readonly cloudCodeRevisions: Entities.CloudCode[];
    readonly titleDataVersions: Entities.TitleData[];
    readonly contentVersions: Entities.FileVersion[];
    readonly onCreate: (clientVersion: Entities.ClientVersion) => void;
}

interface State extends BaseDialogState {
    readonly version: string;
    readonly live: boolean;
    readonly cloudCodeRevision: number;
    readonly titleDataVersion: string;
    readonly contentVersion: string;
    readonly forceUpdate: boolean;
}

export class CreateClientVersionDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.clientVersions !== prevProps.clientVersions) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const { cloudCodeRevisions, titleDataVersions, contentVersions } = this.props;
        const { version, cloudCodeRevision, titleDataVersion, contentVersion } = this.state;

        const versionsViews = getVersionsViews(cloudCodeRevisions, titleDataVersions, contentVersions, true);
        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Version</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={version}
                        label=""
                        required={true}
                        onChange={this.updateVersion}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Cloud Code</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={cloudCodeRevision}
                        onChange={this.handleCloudCodeRevisionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.cloudCodesViews}
                    </Select>
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="select-duration">Title Data</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={titleDataVersion}
                        onChange={this.handleTitleDataVersionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.titleDataVersionsViews}
                    </Select>
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="select-content">Content</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={contentVersion}
                        onChange={this.handleContentVersionChange}
                        native={true}
                        style={{width: '100%'}}
                        variant={'outlined'}
                    >
                        {versionsViews.contentVersionsViews}
                    </Select>
                </Grid>
            </Grid>
        );

    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validateVersion()} />
        );
    }

    protected initState(): State {
        const { clientVersions } = this.props;
        return {
            version: '',
            live: clientVersions.length === 0,
            cloudCodeRevision: -1,
            titleDataVersion: "",
            contentVersion: "",
            forceUpdate: false,
        };
    }

    protected handleCloudCodeRevisionChange = (event: SelectChangeEvent<number>) => this.setState({ cloudCodeRevision: event.target.value as number });

    protected handleTitleDataVersionChange = (event: SelectChangeEvent) => this.setState({ titleDataVersion: event.target.value });

    protected handleContentVersionChange = (event: SelectChangeEvent) => this.setState({ contentVersion: event.target.value });

    private updateVersion = (version: string) => this.setState({version});

    private validateVersion(): boolean {
        const { version } = this.state;
        const { clientVersions } = this.props;
        if (!version) {
            return false;
        }
        return !clientVersions.some( cv => cv.version === version);
    }

    private onSubmit = () => {
        const { version, live, cloudCodeRevision, titleDataVersion, contentVersion, forceUpdate } = this.state;
        if (!this.validateVersion()) {
            return;
        }
        const clientVersion: Entities.ClientVersion = {
            version,
            live,
            cloudCodeRevision,
            titleDataVersion,
            forceUpdate,
            contentVersion,
            updated: 0,
        };
        this.props.onCreate(clientVersion);
        this.onClose();
    };
}
