import * as React from 'react';
import { Button, LabelCheckBox, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly versions: Entities.TitleData[];
    readonly onCreate: (version: string, isDefault: boolean) => void;
}

interface State extends BaseDialogState {
    readonly version: string;
    readonly isDefault: boolean;
}

export class CreateTitleDataDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { version, isDefault } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Version</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        error={!this.validateVersion()}
                        value={version}
                        label=""
                        required={true}
                        onChange={this.updateVersion}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <LabelCheckBox
                        label="Make Default"
                        checked={isDefault}
                        onChange={this.updateIsDefault}
                        readOnly={false}
                        labelPlacement={'end'}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validateVersion()} />
        );
    }

    protected initState(): State {
        return {
            version: '',
            isDefault: false,
        };
    }

    private validateVersion(): boolean {
        const { versions } = this.props;
        const { version } = this.state;
        if (!version) {
            return false;
        }
        return !versions.some(v => v.version === version);
    }

    private updateVersion = (version: string) => this.setState({ version });
    private updateIsDefault = (isDefault: boolean) => this.setState({ isDefault });

    private onSubmit = () => {
        const { version, isDefault } = this.state;
        if (!this.validateVersion()) {
            return;
        }
        this.props.onCreate(version, isDefault);
        this.onClose();
    };
}
