import * as React from 'react';
import { connect } from 'react-redux';
import { BaseAnalyzePlayers, BaseElement, FilterDates, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import { ActionRequest } from '../actions';
import { formatDate, getPlayerLink } from '../utils';
import { Grid2 as Grid } from '@mui/material';
import { default as moment, Moment } from 'moment';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

interface State {
    readonly startTimeSpam: Moment;
    readonly endTimeSpam: Moment;
    readonly startTimeSpamBackup: Moment;
    readonly endTimeSpamBackup: Moment;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        startTimeSpam: moment.parseZone().subtract(24, 'hours'),
        endTimeSpam: moment.parseZone(),
        startTimeSpamBackup: moment.parseZone().subtract(24, 'hours'),
        endTimeSpamBackup: moment.parseZone(),
    };

    protected renderContainer(): React.JSX.Element {
        return (
            <BaseAnalyzePlayers {...this.props} >
                {this.renderContent()}
            </BaseAnalyzePlayers>
        );
    }

    private renderContent = () => {
        const columns = [
            { title: 'Player Id', field: 'playerId'},
            { title: 'CustomId', field: 'customId'},
            { title: 'Created', field: 'created'},
        ];

        const { startTimeSpam, endTimeSpam, startTimeSpamBackup, endTimeSpamBackup } = this.state;
        const tableRef = React.createRef();
        const onSearch = () => {
            // @ts-ignore
            tableRef.current.onSearchChangeDebounce();
        };

        const onFilterDates = (start: Moment, end: Moment) => {
            this.setState({ startTimeSpam: start, endTimeSpam: end});
            onSearch();
        };

        const tableRefBackup = React.createRef();
        const onSearchBackup = () => {
            // @ts-ignore
            tableRefBackup.current.onSearchChangeDebounce();
        };

        const onFilterDatesBackup = (start: Moment, end: Moment) => {
            this.setState({ startTimeSpamBackup: start, endTimeSpamBackup: end});
            onSearchBackup();
        };

        return (
            <Grid container={true} spacing={2}>
                <Grid size={12}>
                    <Table
                        tableRef={tableRef}
                        title={<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px 0px' }}>
                            <FilterDates
                                start={startTimeSpam}
                                end={endTimeSpam}
                                onFilter={onFilterDates}
                            />
                        </div>}
                        columns={columns}
                        options={{
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: true,
                            draggable: false,
                        }}
                        data={this.getData}
                    />
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <Table
                        tableRef={tableRef}
                        title={<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px 0px' }}>
                            <FilterDates
                                start={startTimeSpamBackup}
                                end={endTimeSpamBackup}
                                onFilter={onFilterDatesBackup}
                            />
                        </div>}
                        columns={columns}
                        options={{
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: true,
                            draggable: false,
                        }}
                        data={this.getBackupData}
                    />
                </Grid>
            </Grid>
        );
    };

    private getData = async (query: any) => {
        const { startTimeSpam, endTimeSpam } = this.state;
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get(`analyze/players/customLoginKeys`, {
            filter: search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
            startTimeSpam: startTimeSpam.valueOf(),
            endTimeSpam: endTimeSpam.valueOf(),
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.data.map((d: any) => {
            return {
                playerId: getPlayerLink(d.playerId),
                customId: d.customId,
                created: formatDate(d.created),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getBackupData = async (query: any) => {
        const { startTimeSpamBackup, endTimeSpamBackup } = this.state;
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get(`analyze/players/customLoginKeysBackup`, {
            filter: search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
            startTimeSpam: startTimeSpamBackup.valueOf(),
            endTimeSpam: endTimeSpamBackup.valueOf(),
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.data.map((d: any) => {
            return {
                playerId: getPlayerLink(d.playerId),
                customId: d.customId,
                created: formatDate(d.created),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
