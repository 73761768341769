import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly onCreate: (folder: string) => void;
}

interface State extends BaseDialogState {
    readonly folder: string;
}

export class CreateFolderDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { folder } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Folder</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        error={!this.validateFolderName()}
                        value={folder}
                        label=""
                        required={true}
                        onChange={this.updateFolder}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="text" color="secondary" disabled={!this.validateFolderName()} />
        );
    }

    private validateFolderName(): boolean {
        const { folder } = this.state;
        return folder !== '';
    }

    private updateFolder = (folder: string) => this.setState({ folder });

    private onSubmit = () => {
        const { folder } = this.state;
        if (!this.validateFolderName()) {
            return;
        }
        this.props.onCreate(folder);
        this.onClose();
    };

    protected initState(): State {
        return {
            folder: '',
        };
    }
}
