import * as React from 'react';
import { FormControlLabel, Grid2 as Grid, InputLabel, Radio, TextField, Typography, RadioGroup } from '@mui/material';
import { Button, Dropzone, LabelCheckBox, Metadata } from '../common';

interface Props {
    readonly type: 'Create' | 'Edit';
    readonly onCreate: (itemId: string, itemClass: string, displayName: string, description: string, tags: string[], customData: string, isStackable: boolean, isTradable: boolean, isLimitedEdition: boolean) => void;
    readonly onCancel: () => void;
    readonly items: Entities.EconomyItem[];
    readonly item?: Entities.EconomyItem;
}

interface State {
    readonly id: string;
    readonly displayName: string;
    readonly description: string;
    readonly itemClass: string;
    readonly tags: string[];
    readonly customData: string;
    readonly consumable: string;
    readonly container: string;
    readonly bundledItems: string[];
    readonly bundledResultTables: string[];
    readonly bundledVirtualCurrencies: string;
    readonly isStackable: boolean;
    readonly isTradable: boolean;
    readonly isLimitedEdition: boolean;
    readonly initialLimitedEditionCount: number;
    readonly file: File | null;
}

export class CreateEconomyItemComponent extends React.Component<Props, State> {
    state = this.initState();

    componentDidMount() {
        const { item } = this.props;
        if (!item) {
            return;
        }
        this.setState(this.initStateFromItem(item));
    }

    componentDidUpdate(prevProps: Props) {
        const { item } = this.props;
        if (!item || !prevProps.item || item.id === prevProps.item.id) {
            return;
        }

        this.setState(this.initStateFromItem(item));
    }

    render() {
        const { type } = this.props;
        const { customData, displayName, description, isStackable, isTradable, isLimitedEdition } = this.state;

        return (
            <Grid container={true} spacing={5}>
                <Grid size={{ xs:12, sm:4 }}>
                    {this.renderProperties()}
                </Grid>
                <Grid size={{ xs:12, sm:4 }}>
                    <Grid container={true} spacing={0}>
                        <Grid size={12}>
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                DISPLAYED TO PLAYERS
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>Display Name</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                label=""
                                value={displayName}
                                variant="outlined"
                                fullWidth={true}
                                onChange={this.handleChange('displayName')}
                            />
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>Description</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                label=""
                                value={description}
                                variant="outlined"
                                multiline={true}
                                rows={4}
                                fullWidth={true}
                                onChange={this.handleChange('description')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs:12, sm:4 }}>
                    <Grid container={true} spacing={0}>
                        <Grid size={12}>
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                CONSUMABLES
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <RadioGroup aria-label="gender" name="gender1" value={0}>
                                <FormControlLabel value="0" control={<Radio />} label="Durable" />
                                <FormControlLabel value="1" control={<Radio />} label="Consumable" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs:12, sm:4 }}>
                    <Grid container={true} spacing={0}>
                        <Grid size={12}>
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                OPTIONS
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <LabelCheckBox
                                label="Is stackable"
                                labelPlacement={'end'}
                                checked={isStackable}
                                onChange={this.handleChangeIsStackable}
                            />
                        </Grid>
                        <Grid size={12}>
                            <LabelCheckBox
                                label="Is tradable"
                                labelPlacement={'end'}
                                checked={isTradable}
                                onChange={this.handleChangeIsTradable}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs:12, sm:4 }}>
                    <Grid container={true} spacing={1}>
                        <Grid size={12}>
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                LIMITED EDITION
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <LabelCheckBox
                                label="Is limited edition"
                                labelPlacement={'end'}
                                checked={isLimitedEdition}
                                onChange={this.handleChangeIsLimitedEdition}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs:12, sm:4 }} />
                <Grid size={12}>
                    <Grid container={true} spacing={1}>
                        <Grid size={12}>
                            <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                                METADATA
                            </Typography>
                        </Grid>
                        <Grid size={12}>
                            <Metadata
                                text={customData}
                                onChange={this.onChangeCustomData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs:12, sm:4 }} />
                <Grid size={12}>
                    <div style={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        gridColumnGap: 20,
                        justifyContent: 'flex-start'
                    }}>
                        <Button
                            text={`${type} Item`}
                            variant="contained"
                            onClick={this.onCreate}
                            disabled={!this.validate()}
                        />
                        <Button
                            text="Cancel"
                            variant="contained"
                            color="secondary"
                            onClick={this.onCancel}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }

    private renderProperties = () => {
        const { items, type } = this.props;
        const { id, itemClass } = this.state;
        const showIdError = type === 'Create' && items.some(i => i.id === id);

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <Typography variant={'subtitle2'} style={{ fontWeight: 'bold' }}>
                        PROPERTIES
                    </Typography>
                </Grid>
                <Grid size={12}>
                    <InputLabel>Item ID</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        label=""
                        value={id}
                        variant="outlined"
                        fullWidth={true}
                        disabled={type === 'Edit'}
                        error={showIdError}
                        helperText={showIdError ? 'Cannot set this item\'s ID to one that already exists.' : ''}
                        onChange={this.handleChange('id')}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Item Class</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        label=""
                        value={itemClass}
                        variant="outlined"
                        fullWidth={true}
                        onChange={this.handleChange('itemClass')}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Tags</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        label=""
                        value={""}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Image</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Dropzone
                        accept={{
                            "image/jpeg": ['.jpeg'],
                            "image/jpg": ['.jpg'],
                            "image/png": ['.png'],
                        }}
                        multiple={false}
                        text={'Drop image file'}
                        description={'Upload image (JPEG, JPG or PNG)'}
                        onDropFile={this.handleDrop}
                    />
                </Grid>
            </Grid>
        );
    };

    private initState(): State {
        return {
            id: '',
            displayName: '',
            description: '',
            itemClass: '',
            tags: [],
            customData: '',
            consumable: '',
            container: '',
            bundledItems: [],
            bundledResultTables: [],
            bundledVirtualCurrencies: '',
            isStackable: false,
            isTradable: false,
            isLimitedEdition: false,
            initialLimitedEditionCount: 0,
            file: null,
        };
    }

    private initStateFromItem(item: Entities.EconomyItem): State {
        const {
            id,
            displayName,
            description,
            itemClass,
            tags,
            customData,
            consumable,
            container,
            bundledItems,
            bundledResultTables,
            bundledVirtualCurrencies,
            isStackable,
            isTradable,
            isLimitedEdition,
            initialLimitedEditionCount,
        } = item;

        return {
            id,
            displayName: displayName || '',
            description: description || '',
            itemClass: itemClass || '',
            tags,
            customData: customData || '',
            consumable: consumable || '',
            container: container || '',
            bundledItems,
            bundledResultTables,
            bundledVirtualCurrencies: bundledVirtualCurrencies || '',
            isStackable,
            isTradable,
            isLimitedEdition,
            initialLimitedEditionCount,
            file: null,
        };
    }

    private onCreate = async () => {
        const { onCreate } = this.props;
        const { id, itemClass, customData, displayName, description, tags, isStackable, isTradable, isLimitedEdition } = this.state;
        onCreate(id, itemClass, displayName, description, tags, customData, isStackable, isTradable, isLimitedEdition);
        this.setState(this.initState());
    };

    private onCancel = async () => {
        this.props.onCancel();
    };

    private validate = () => {
        const { id } = this.state;
        const { type, items } = this.props;
        const showIdError = type === 'Create' && items.some(i => i.id === id);
        return !showIdError && this.state.id !== '';
    }

    private handleDrop = (file: File) => this.setState({ file });

    private handleChange = (name: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({...this.state, [name]: event.target.value});
    };

    private handleChangeIsStackable = (isStackable: boolean) => this.setState({isStackable});

    private handleChangeIsTradable = (isTradable: boolean) => this.setState({isTradable});

    private handleChangeIsLimitedEdition = (isLimitedEdition: boolean) => this.setState({isLimitedEdition});

    private onChangeCustomData = (customData: string) => this.setState({ customData });
}
