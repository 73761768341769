import { PromoCampaignActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState } from '../reducers';

export const list = async (): Promise<void> => {
    const promoCampaigns = await Request.get(`promoCampaign/list`);
    const preDefinedItems = await Request.get(`costumerSupport/compensationPreDefineItems/list`);

    dispatch({
        type: PromoCampaignActionType.Update,
        payload: { promoCampaigns, preDefinedItems},
    });
};

export const get = async (campaignId: string): Promise<void> => {
    const getResult = await Request.get(`promoCampaign/get/${campaignId}`);
    dispatch({
        type: PromoCampaignActionType.Update,
        payload: {
            details: {
                campaign: getResult.campaign,
                codes: getResult.codes,
                rewards: getResult.rewards,
                players: getResult.players,
            }
        },
    });
};

export const create = async(
    name: string,
    start: number,
    end: number,
    maxRedeems: number,
    scheduleColor: string,
    developerOnly: boolean,
    codes: string[],
    rewards: Entities.PromoCampaignReward[],
): Promise<void> => {
    await Request.post(
        `promoCampaign/create`,
        {
            name,
            start,
            end,
            maxRedeems,
            scheduleColor,
            developerOnly,
            codes,
            rewards,
        },
    );
};

export const remove = async (campaignId: string): Promise<void> => {
    await Request.post(`promoCampaign/${campaignId}/delete`);
};

export const removePlayer = async (campaignId: string, playerId: string): Promise<void> => {
    await Request.post(`promoCampaign/${campaignId}/${playerId}/deletePlayer`);
    const state = getState().promoCampaign;
    if (!state.details) {
        return;
    }
    dispatch({
        type: PromoCampaignActionType.Update,
        payload: {
            details: {
                campaign: state.details.campaign,
                codes: state.details.codes,
                rewards: state.details.rewards,
                players: state.details.players.filter(p => p.playerId !== playerId),
            }
        },
    });
};
