import React from 'react';
import { CircularProgress, Grid2 as Grid, InputLabel, Select, MenuItem, SelectChangeEvent, Divider } from '@mui/material';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { Button, TextField } from './input';
import { Metadata } from './Metadata';
import { AutomaticPlayerActionType } from '../../enums';
import { ActionRequest } from '../../actions';
import { styled } from '@mui/styles';

interface Props {
    readonly actions?: Entities.AutomaticPlayerAction[];
    readonly readOnly?: boolean;
    readonly style?: React.CSSProperties;
    readonly onUpdateActions?: (actions: Entities.AutomaticPlayerAction[]) => void;
}

interface State {
    readonly loading: boolean;
    readonly actions: Entities.AutomaticPlayerAction[];
    readonly scripts: Entities.CloudCode[];
    readonly methods: string[];
    readonly titleData: Entities.TitleData[];
}

export class AutomatePlayerAction extends React.Component<Props, State> {
    state: State = {
        loading: false,
        actions: [],
        scripts: [],
        methods: [],
        titleData: [],
    };

    async componentDidMount() {
        const { actions } = this.props;
        if (actions) {
            this.setState({actions});
        } else{
            this.addAction();
        }
        await this.getMethods();
    }

    render() {
        const { readOnly, style } = this.props;
        const { loading, actions } = this.state;
        const actionsElements: React.JSX.Element[] = [];
        actions.forEach((a, i) => {
            switch (a.action) {
                case AutomaticPlayerActionType.SendPushNotification:
                    actionsElements.push(this.renderSendPushNotificationAction(i, a.params as Entities.SendPushNotificationParams));
                    break;
                case AutomaticPlayerActionType.ExecuteCloudCode:
                    actionsElements.push(this.renderExecuteCloudCodeAction(i, a.params as Entities.ExecuteCloudCodeParams));
                    break;
            }
        });

        return (
            <Grid container={true} justifyContent="center" spacing={2} style={{ width: '100%', ...style }}>
                <Grid size={12}>
                    {loading && (
                        <CircularProgress style={{margin: 20}}/>
                    )}
                    {!loading && (
                        <>
                            <Grid container={true} spacing={1} style={{width: '100%', margin: 0, padding: 10}}>
                                {actions.length === 0 && (
                                    <Grid size={12} style={{border: '1px solid rgb(223, 223, 223)', padding: 10}}>
                                        No actions
                                    </Grid>
                                )}
                                {actionsElements.map((element, index) => (
                                    <>
                                        {index > 0 && (
                                            <Grid size={12}>
                                                <StyledDivider />
                                            </Grid>
                                        )}
                                        <Grid size={12} style={{ padding: 8 }}>
                                            {element}
                                        </Grid>
                                    </>
                                ))}
                            </Grid>
                        </>
                    )}
                </Grid>
                {!readOnly && (
                    <Grid size={12}>
                        <StyledButton text="Add action" icon={AddOutlined} onClick={this.addAction} />
                    </Grid>
                )}
            </Grid>
        );
    }

    private onChangeActions = (actions: Entities.AutomaticPlayerAction[]) => {
        const { onUpdateActions } = this.props;
        if (!onUpdateActions) {
            return;
        }
        this.setState({ actions });
        onUpdateActions(actions);
    };

    private renderActionType = (index: number, value: AutomaticPlayerActionType) => {
        const { actions, scripts, methods, titleData } = this.state;

        const onChangeMethod = (event: SelectChangeEvent<number>) => {
            const action =  event.target.value as AutomaticPlayerActionType;
            actions[index].action = action;
            const scriptSelected = scripts.find(s => s.live);
            const titleDataSelected = titleData.find(t => t.isDefault);
            switch (action) {
                case AutomaticPlayerActionType.SendPushNotification:
                    actions[index].params = {
                        title: '',
                        body: '',
                    };
                    break;
                case AutomaticPlayerActionType.ExecuteCloudCode:
                    actions[index].params = {
                        revision: scriptSelected ? scriptSelected.revision : -1,
                        titleData: titleDataSelected ? titleDataSelected.version : '',
                        method: methods[0],
                        arguments: '{}',
                    };
                    break;
            }
            this.onChangeActions(actions);
        };

        return (
            <>
                <StyledInputLabel id={`action_type_${index}`}>
                    Type
                </StyledInputLabel>
                <Select
                    labelId={`action_type_${index}`}
                    variant={'outlined'}
                    value={value}
                    onChange={onChangeMethod}
                    disableUnderline={true}
                    style={{ backgroundColor: 'white', minWidth: '100%'}}
                >
                    <MenuItem key={AutomaticPlayerActionType.SendPushNotification} value={AutomaticPlayerActionType.SendPushNotification}>
                        Send Push Notification
                    </MenuItem>
                    <MenuItem key={AutomaticPlayerActionType.ExecuteCloudCode} value={AutomaticPlayerActionType.ExecuteCloudCode}>
                        Execute Cloud Code
                    </MenuItem>
                </Select>
            </>
        );
    };

    private renderSendPushNotificationAction = (index: number, params: Entities.SendPushNotificationParams) => {
        const { readOnly } = this.props;
        const { actions } = this.state;

        const onTitleChange = (value: string) => {
            params.title = value;
            actions[index].params = params;
            this.onChangeActions(actions);
        };

        const onBodyChange = (value: string) => {
            params.body = value;
            actions[index].params = params;
            this.onChangeActions(actions);
        };

        const onRemove = () => this.removeAction(index);

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    {this.renderActionType(index, AutomaticPlayerActionType.SendPushNotification)}
                </Grid>
                <Grid size={12}>
                    <StyledInputLabel id={`title-${index}`}>
                        Title
                    </StyledInputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        variant="outlined"
                        value={params.title}
                        onChange={onTitleChange}
                    />
                </Grid>
                <Grid size={12}>
                    <StyledInputLabel id={`body-${index}`}>
                        Body
                    </StyledInputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        variant="outlined"
                        value={params.body}
                        onChange={onBodyChange}
                    />
                </Grid>
                {!readOnly && (
                    <Grid size={12}>
                        <StyledButton text="Remove action" icon={DeleteOutlined} onClick={onRemove} />
                    </Grid>
                )}
            </Grid>
        );
    };

    private getMethods = async (revision?: number): Promise<void> => {
        this.setState({loading: true});
        const data = await ActionRequest.get(`cloudCode/${revision}/getMethods`);
        if (!data) {
            return;
        }
        this.setState({
            loading: false,
            scripts: data.scripts,
            methods: data.methods,
            titleData: data.titleData,
        });
    }

    private renderExecuteCloudCodeAction = (index: number, params: Entities.ExecuteCloudCodeParams) => {
        const { readOnly } = this.props;
        const { actions, scripts, methods, titleData } = this.state;

        const onChangeRevision = (event: SelectChangeEvent<number>) => {
            params.revision = event.target.value as number;
            actions[index].params = params;
            this.getMethods(params.revision).then(() => this.onChangeActions(actions));
        };

        const onChangeTitleData = (event: SelectChangeEvent) => {
            params.titleData = event.target.value;
            actions[index].params = params;
            this.onChangeActions(actions);
        };

        const onChangeMethod = (event: SelectChangeEvent) => {
            params.method = event.target.value;
            actions[index].params = params;
            this.onChangeActions(actions);
        };

        const onChangeArguments = (args: string) => {
            params.arguments = args;
            actions[index].params = params;
            this.onChangeActions(actions);
        };

        const onRemove = () => this.removeAction(index);

        return (
            <Grid container={true} spacing={0} style={{borderBottom: '2px solid rgb(223, 223, 223)', margin: 0, width: '100%'}}>
                <Grid size={{ xs:12, sm:4 }}>
                    {this.renderActionType(index, AutomaticPlayerActionType.ExecuteCloudCode)}
                </Grid>
                <Grid size={{ xs:12, sm:8 }}>
                    <Grid container={true} spacing={2}>
                        <Grid size={12}>
                            <StyledInputLabel id={`revision-label-${index}`}>
                                Revision
                            </StyledInputLabel>
                            <Select
                                labelId={`revision-label-${index}`}
                                variant={'outlined'}
                                value={params.revision}
                                onChange={onChangeRevision}
                                disableUnderline={true}
                                style={{ backgroundColor: 'white', minWidth: '100%'}}
                            >
                                {scripts.map(value => (
                                    <MenuItem key={value.revision} value={value.revision}>
                                        {`Revision ${value.revision} ${value.live ? '(live)' : ''}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid size={12}>
                            <StyledInputLabel id={`title-data-label-${index}`}>
                                Title Data Version
                            </StyledInputLabel>
                            <Select
                                labelId={`title-data-label-${index}`}
                                variant={'outlined'}
                                value={params.titleData}
                                onChange={onChangeTitleData}
                                disableUnderline={true}
                                style={{ backgroundColor: 'white', minWidth: '100%'}}
                            >
                                {titleData.map(value => (
                                    <MenuItem key={value.version} value={value.version}>
                                        {`${value.version} ${value.isDefault ? '(live)' : ''}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid size={12}>
                            <StyledInputLabel id={`function-name-label-${index}`}>
                                Function Name
                            </StyledInputLabel>
                            <Select
                                labelId={`function-name-label-${index}`}
                                variant={'outlined'}
                                value={params.method}
                                onChange={onChangeMethod}
                                disableUnderline={true}
                                style={{ backgroundColor: 'white', minWidth: '100%'}}
                            >
                                {methods.map(method => (
                                    <MenuItem key={method} value={method}>
                                        {method}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid size={12}>
                            <StyledInputLabel id={`arguments-${index}`}>
                                Arguments
                            </StyledInputLabel>
                            <Metadata
                                text={params.arguments}
                                readOnly={readOnly}
                                onChange={onChangeArguments}
                            />
                        </Grid>
                        {!readOnly && (
                            <Grid size={12}>
                                <StyledButton text="Remove action" icon={DeleteOutlined} onClick={onRemove} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private addAction = () => {
        const { actions } = this.state;
        const action: Entities.AutomaticPlayerAction = {
            action: AutomaticPlayerActionType.SendPushNotification,
            params: {
                title: '',
                body: '',
            }
        };
        actions.push(action);
        this.onChangeActions(actions);
    }

    private removeAction = (index: number) => {
        const { actions } = this.state;
        this.onChangeActions(actions.filter((v, i) => i !== index));
    }
}

const StyledInputLabel = styled(InputLabel)(({
    fontWeight: 'bold',
    color: 'rgb(47, 50, 58)',
    marginBottom: 8,
}));

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&:hover': {
        backgroundColor: 'transparent',
    }
}));

const StyledDivider = styled(Divider)(({
    backgroundColor: 'rgb(245, 245, 245, 0.5)',
    width: '100%',
    margin: '8px 0px',
}));
