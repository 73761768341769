import * as React from 'react';
import { TitleNewsStatus } from '../../enums';
import { Grid2 as Grid, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { Dropzone, Metadata, TextField, Button } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { default as moment, Moment } from 'moment';
import { DateTimePicker, DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';

interface Props extends BaseDialogProps {
    readonly segments: Entities.Segment[];
    readonly selectedTitleNews: Entities.TitleNews | null;
    readonly onUpdate: (id: string, date: number, status: TitleNewsStatus, title: string, body: string, file: any, metadata: string, segmentFilterId: string | null) => void;
}

interface State extends BaseDialogState {
    readonly date: Moment;
    readonly status: TitleNewsStatus;
    readonly title: string;
    readonly body: string;
    readonly file: File | null;
    readonly imageUrl: string;
    readonly metadata: string;
    readonly segmentFilterId: string;
}

export class UpdateTitleNewsDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        const { selectedTitleNews, open } = this.props;
        if ( open && selectedTitleNews && !prevProps.selectedTitleNews) {
            this.setState(this.initState());
        }
    }
    protected renderContent(): React.JSX.Element {
        const { segments } = this.props;
        const { date, status, title, body, imageUrl, metadata, segmentFilterId } = this.state;
        const statusOptions: TitleNewsStatus[] = [
            TitleNewsStatus.None,
            TitleNewsStatus.Unpublished,
            TitleNewsStatus.Published,
            TitleNewsStatus.Archived,
        ];

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Title</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={title}
                        label=""
                        required={true}
                        onChange={this.updateTitle}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Start</InputLabel>
                </Grid>
                <Grid size={12}>
                    <DateTimePicker
                        label=""
                        value={date}
                        onChange={this.updateDate}
                        disablePast={false}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Status</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={status}
                        onChange={this.updateStatus}
                        variant={'outlined'}
                        style={{width: '100%'}}
                    >
                        {statusOptions.map(value => (
                            <MenuItem key={value} value={value}>
                                {`${TitleNewsStatus[value]}`}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid size={12}>
                    <InputLabel>Image</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Dropzone
                        accept={{
                            "image/jpeg": ['.jpeg'],
                            "image/jpg": ['.jpg'],
                            "image/png": ['.png'],
                        }}
                        preview={imageUrl}
                        multiple={false}
                        text={'Drop image file'}
                        description={'Upload image (JPEG, JPG or PNG)'}
                        onDropFile={this.handleDrop}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Metadata
                        text={metadata}
                        onChange={this.handleChangeMetadata}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Body</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        label=""
                        value={body}
                        variant="outlined"
                        multiline={true}
                        rows={4}
                        fullWidth={true}
                        onChange={this.updateBody}
                    />
                </Grid>
                {segments && segments.length > 0 && (
                    <>
                        <Grid size={12}>
                            <InputLabel>Filter Segment</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <Select
                                value={segmentFilterId}
                                onChange={this.updateSegmentFilterId}
                                variant={'outlined'}
                                style={{width: '100%'}}
                            >
                                <MenuItem key={'none'} value={'none'}>
                                    {`None`}
                                </MenuItem>
                                {segments.map(segment => (
                                    <MenuItem key={segment.id} value={segment.id}>
                                        ${segment.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onUpdate} variant="text" color="secondary" disabled={!this.validate()}/>
        );
    }

    protected initState(): State {
        const { selectedTitleNews } = this.props;
        if (!selectedTitleNews) {
            return {
                date: moment.parseZone(),
                status: TitleNewsStatus.None,
                title: '',
                body: '',
                file: null,
                imageUrl: '',
                metadata: '',
                segmentFilterId: 'none',
            };
        }

        return {
            date: moment(selectedTitleNews.timestamp),
            status: selectedTitleNews.status,
            title: selectedTitleNews.title,
            body: selectedTitleNews.body,
            file: null,
            imageUrl: selectedTitleNews.imageUrl,
            metadata: selectedTitleNews.metadata,
            segmentFilterId: selectedTitleNews.segmentFilterId !== '' ? selectedTitleNews.segmentFilterId : 'none',
        };
    }

    private updateDate = (date: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (date === null) {
            return;
        }
        this.setState({ date });
    }

    private updateStatus = (event: SelectChangeEvent<number>) => this.setState({ status: event.target.value as number });

    private updateSegmentFilterId = (event: SelectChangeEvent) => this.setState({ segmentFilterId: event.target.value as string });

    private updateTitle = (title: string) => this.setState({ title });

    private updateBody = (body: string) => this.setState({ body });

    private handleDrop = (file: File) => this.setState({ file });

    private handleChangeMetadata = (metadata: string) => this.setState({ metadata });

    private validate(): boolean {
        if (!this.validateStatus()) {
            return false;
        }

        const { title, body } = this.state;
        return !(title === '' || body === '');
    }

    private validateStatus(): boolean {
        const { status } = this.state;
        return status !== TitleNewsStatus.None;

    }

    private onUpdate = () => {
        if (!this.validateStatus()) {
            return;
        }
        const { selectedTitleNews } = this.props;
        if (!selectedTitleNews) {
            return;
        }

        const { newsId } = selectedTitleNews;
        const { date, status, title, body, file, metadata, segmentFilterId } = this.state;
        const dateValue = date.utc().valueOf();
        const segmentId = segmentFilterId !== 'none' ? segmentFilterId : null;
        this.props.onUpdate(newsId, dateValue, status, title, body, file, metadata, segmentId);
        this.onClose();
    };
}
