import React from 'react';
import { ContainerContext, mapProps } from './index';
import { connect } from 'react-redux';
import { CardMedia, Typography, Link, Grid2 as Grid } from '@mui/material';
import actions from '../actions';
import { Button, GoogleLoginButton, EmailLogin } from '../components';
import { toLink } from '../utils';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import { styled } from '@mui/styles';
import { AppState, StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly app: AppState;
    readonly login: boolean;
}
interface State {
    readonly googleLogin: boolean;
}

class Container extends React.PureComponent<Props, State> {
    state: State = {
        googleLogin: true,
    };

    componentDidMount() {
        actions.title.resetTitle();
    }

    render() {
        if (this.props.loginUser) {
            return <Navigate to="/titles" replace={true} />;
        }

        const { login, app } = this.props;
        const { googleLogin } = this.state;
        if (login) {
            return;
        }
        const { current } = app;
        const clientId = current ? current.googleClientId : '';

        return (
            <main
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <section style={{
                    height: 'calc(100% - 60px)',
                    display: 'flex',
                    backgroundImage: `url('${process.env.PUBLIC_URL}/images/nucleo-background.png')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}>
                    <Grid container={true} spacing={2} style={{ margin: 'auto', width: 450 }}>
                        <Grid size={{ xs:12 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CardMedia
                                sx={{
                                    width: 350,
                                    height: 352
                                }}
                                image={`${process.env.PUBLIC_URL}/images/nucleo-logo.png`}
                                title="Nucleo"
                            />
                        </Grid>
                        <Grid size={{ xs:12 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h4" style={{textAlign: 'center', color: '#ecedee', fontWeight: 700, textTransform: 'uppercase', letterSpacing: 8}}>
                                Nucleo Portal
                            </Typography>
                        </Grid>
                        <Grid size={{ xs:12 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {googleLogin && (
                                <GoogleLoginButton
                                    clientId={clientId}
                                    onSuccess={this.onSuccess}
                                    onFailure={this.onFailure}
                                    responseType={'code'}
                                />
                            )}
                            {!googleLogin && <EmailLogin onSubmit={this.onSubmit} />}
                        </Grid>
                        <Grid size={{ xs:12 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <StyledButton
                                text={googleLogin ? 'Login With Email' : 'Login With Google'}
                                onClick={this.onChangeLoginMethod}
                            />
                        </Grid>
                    </Grid>
                </section>
                <section style={{
                    height: 50,
                    backgroundColor: 'white',
                    color: '#666',
                    display: 'grid',
                    gridAutoFlow: 'column',
                    justifyContent: 'center'
                }}>
                    <StyledLink href={'http://www.nightgardenstudio.com'} >
                        <CardMedia
                            style={{
                                height: 40,
                                width: 40,
                                margin: 'auto',
                            }}
                            image={`${process.env.PUBLIC_URL}/images/nightgarden_logo.png`}
                            title="Night Garden"
                        />
                    </StyledLink>
                    <Typography variant="subtitle2" style={{margin: 'auto', padding: '0px 5px'}}>
                        Copyright © 2019 Night Garden Studio. All rights reserved
                    </Typography>
                </section>
            </main>
        );
    }

    private onSuccess = async (code: any) => {
        await actions.auth.loginWithGoogle(code);
        toLink(this.props, `/titles`);
    };

    private onFailure = (error: any) => console.error(error);

    private onSubmit = async (username: string, password: string) => {
        await actions.auth.login(username, password);
        toLink(this.props, `/titles`);
    };

    private onChangeLoginMethod = () => this.setState({googleLogin: !this.state.googleLogin});
}

const StyledLink = styled(Link)(({
    display: 'inherit',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#F2F2F2',
    padding: '10px 0',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));

const StyledButton = styled(Button)(({
    color: 'white',
    fontSize: '0.875rem',
    borderColor: 'rgba(0, 0, 0, 0)',
    fontWeight: 500,
    lineHeight: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'underline',
    padding: '6px 16px',
    backgroundColor: 'transparent',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
    },
    '&:focus': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
    },
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    app: state.app,
    login: !!state.user.loginUser,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate} />);
};
export default connect(mapStateToProps)(AppContainer);
