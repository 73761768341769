import { TitleDataActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState } from '../reducers';
import * as FileSaver from 'file-saver';

export const get = async (version?: string): Promise<void> => {
    version = version ? version : 'default';
    const titleData = await Request.get(`titleData/${version}`);
    dispatch({
        type: TitleDataActionType.Update,
        payload: titleData,
    });
};

export const create = async (version: string, isDefault: boolean): Promise<void> => {
    const titleData = await Request.post(`titleData/create`, {version, isDefault});
    const state = getState().titleData;
    dispatch({
        type: TitleDataActionType.Update,
        payload: {
            versions: [
                ...state.versions,
                titleData
            ],
            titleData: isDefault ? titleData : state.titleData,
        },
    });
};

export const remove = async (version: string): Promise<void> => {
    await Request.post(`titleData/${version}/remove`);

    const state = getState().titleData;
    const versions = state.versions.filter(v => v.version !== version);
    dispatch({
        type: TitleDataActionType.Update,
        payload: {
            ...state,
            versions,
        },
    });
};

export const setAsDefault = async (version: string): Promise<void> => {
    await Request.post(`titleData/${version}/setAsDefault`);
    const state = getState().titleData;
    dispatch({
        type: TitleDataActionType.Update,
        payload: {
            ...state,
            versions: state.versions.map( v => {
                v.isDefault = v.version === version;
                return v;
            }),
        },
    });
};

export const exportItems = async (version: string): Promise<void> => {
    const exportedData = await Request.post(`titleData/${version}/exportItems`);
    const filename = `title-data-${version}.json`;
    const file = new File([JSON.stringify(exportedData, null, 2)], filename, { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(file);
};

export const importItems = async (version: string, data: Record<string, string>): Promise<void> => {
    await Request.post(`titleData/${version}/importItems`, { data });
};

export const updateItem = async (version: string, key: string, value: string): Promise<void> => {
    const data: Entities.TitleDataItem = { key, value };
    await Request.post(`titleData/${version}/updateItem`, data);
};

export const removeItem = async (version: string, key: string): Promise<void> => {
    await Request.post(`titleData/${version}/removeItem`, { key });
};

export const removeAllItems = async (version: string): Promise<void> => {
    await Request.post(`titleData/${version}/removeAllItems`);
};
