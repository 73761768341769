import { DevOpsActionType } from '../enums';
import { Request } from '../utils';
import { dispatch } from '../reducers';

export const getCloudBuildDetails = async (): Promise<void> => {
    const details: Entities.GetCloudBuildDetail | null = await Request.get(`devOps/cloudBuild/getDetails`);
    dispatch({
        type: DevOpsActionType.Update,
        payload: {
            jobs: details ? details.jobs : [],
        },
    });
};

export const getCloudBuildVersionDetails = async (cloudBuildId: string, buildNumber: string): Promise<void> => {
    const details: Entities.GetCloudBuildDetail | null = await Request.get(`devOps/cloudBuild/${cloudBuildId}/${buildNumber}/getDetails`);
    dispatch({
        type: DevOpsActionType.Update,
        payload: {
            jobVersion: details ? details : null,
        },
    });
};

export const addJenkinsJob = async (name: string, username: string, password: string, url: string, jobName: string): Promise<void> => {
    await Request.post(`devOps/cloudBuild/addJenkinsJob`, { name, username, password, url, jobName });
    await getCloudBuildDetails();
};

export const addUnityCloudJob = async (name: string, apiKey: string, orgId: string, projectId: string, targetId: string): Promise<void> => {
    await Request.post(`devOps/cloudBuild/addUnityCloudJob`, { name, apiKey, orgId, projectId, targetId });
    await getCloudBuildDetails();
};

export const removeJenkinsJob = async (id: string): Promise<void> => {
    await Request.post(`devOps/cloudBuild/removeJenkinsJob`, { id });
    await getCloudBuildDetails();
};

export const removeUnityCloudJob = async (id: string): Promise<void> => {
    await Request.post(`devOps/cloudBuild/removeUnityCloudJob`, { id });
    await getCloudBuildDetails();
};

export const editJob = async (id: string, name: string): Promise<void> => {
    await Request.post(`devOps/cloudBuild/editJob`, { id, name });
    await getCloudBuildDetails();
};

export const startCloudBuild = async (ids: string[], clean: boolean): Promise<void> => {
    await Request.post(`devOps/cloudBuild/start`, { ids, clean });
    await getCloudBuildDetails();
};

export const cancelCloudBuild = async (cloudBuildId: string, buildNumber: number): Promise<void> => {
    await Request.post(`devOps/cloudBuild/cancel`, { cloudBuildId, buildNumber });
};
