import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import {
    BaseContainer,
    BaseElement,
    ChangeStateGroupPlayerDialog,
    JsonDialog,
    Metadata,
    Table,
    WarningDialog,
} from '../components';
import {
    formatDate,
    getPlayerLink,
    parseJson,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../utils';
import { ContainerContext, mapProps } from './index';
import { GroupHistoryType, GroupPrivacy, PlayerGroupState, UserTitleRole } from '../enums';
import { Grid2 as Grid, CardMedia } from '@mui/material';
import {
    DeleteOutlineOutlined,
    DoDisturbOutlined,
    ExpandMore,
    GroupAddOutlined,
    VerifiedOutlined,
    GroupWork as GroupWorkIcon,
    ManageAccountsOutlined,
    Circle,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly groupId: string;
    readonly details?: Entities.Group;
}

type DialogType = 'Metadata' | 'AddPlayer' | 'DeletePlayer' | 'ApproveApplicant' | 'RejectApplicant' | 'ChangePlayerState';
interface State {
    readonly openDialog: DialogType | null;
    readonly metadataSelected: string;
    readonly selectedPlayerGroup: Entities.PlayerGroup | null;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        metadataSelected: '',
        selectedPlayerGroup: null,
    };

    async componentDidMount() {
        const { groupId } = this.props;
        await actions.group.get(groupId);
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderMetadataDialog()}
                {this.renderApprovePlayerApplicationDialog()}
                {this.renderApprovePlayerApplicationDialog()}
                {this.renderRejectPlayerApplicationDialog()}
                {this.renderChangeStatePlayerDialog()}
                {this.renderConfirmDeletePlayerDialog()}
            </>
        );
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Groups',
                to: `/group/all`,
            },
            {
                text: this.props.groupId,
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                title = {'Group Details'}
                TitleIcon = {GroupWorkIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    {this.renderDetails()}
                </Grid>
                <Grid size={12}>
                    {this.renderMetadata()}
                </Grid>
                <Grid size={12}>
                    {this.renderPlayers()}
                </Grid>
                <Grid size={12}>
                    {this.renderHistory()}
                </Grid>
            </Grid>
        );
    };

    private renderDetails = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Details
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ backgroundColor: 'white' }}>
                    <Grid container={true} justifyContent="center" spacing={1}>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Name</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {details.name}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Tag</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            #{details.tag}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Player Count</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {details.playersCount}/{details.maxPlayersCount}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Privacy</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {GroupPrivacy[details.privacy].replace(/([A-Z])/g, ' $1').trim()}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Created at</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {formatDate(details.created)}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Edited at</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {formatDate(details.updated)}
                        </Grid>
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderMetadata = () => {
        const { details } = this.props;
        if (!details) {
            return <></>;
        }

        console.log(details.metadata);
        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Metadata
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Metadata
                        text={details.metadata}
                        readOnly={true}
                        style={{ width: '100%' }}
                        keyValueContainerStyle={{ boxShadow: 'none' }}
                        showBox={false}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderPlayers = () => {
        const { details, userTitleRole } = this.props;
        if (!details) {
            return;
        }

        const columns: any[] = [
            { title: 'Player', field: 'player' },
            { title: 'State', field: 'state'},
            { title: 'Metadata', field: 'metadata'},
        ];

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Players
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Table
                        data={this.getPlayers}
                        columns={columns}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: true,
                            sorting: true,
                            draggable: false,
                            actionsColumnIndex: -1,
                        }}
                        actions = {
                            userTitleRole > UserTitleRole.Viewer ?
                                [
                                    {
                                        hidden: userTitleRole === UserTitleRole.Viewer,
                                        tooltip: 'Add',
                                        position: 'toolbar',
                                        icon: GroupAddOutlined,
                                        onClick: this.openAddPlayerDialog,
                                    },
                                    rowData => ({
                                        hidden: rowData.playerGroup.state !== PlayerGroupState.JoinRequest,
                                        icon: VerifiedOutlined,
                                        tooltip: 'Approve Application',
                                        iconProps: {
                                            color: 'primary',
                                        },
                                        onClick: () => this.openApprovePlayerApplicationDialog(rowData.playerGroup)
                                    }),
                                    rowData => ({
                                        hidden: rowData.playerGroup.state !== PlayerGroupState.JoinRequest,
                                        icon: DoDisturbOutlined,
                                        tooltip: 'Reject Application',
                                        iconProps: {
                                            color: 'primary',
                                        },
                                        onClick: () => this.openRejectPlayerApplicationDialog(rowData.playerGroup)
                                    }),
                                    rowData => ({
                                        hidden: rowData.playerGroup.state === PlayerGroupState.JoinRequest,
                                        icon: ManageAccountsOutlined,
                                        tooltip: 'Change State',
                                        iconProps: {
                                            color: 'primary',
                                        },
                                        onClick: () => this.openChangeStatePlayerDialog(rowData.playerGroup)
                                    }),
                                    rowData => ({
                                        hidden: rowData.playerGroup.state === PlayerGroupState.JoinRequest,
                                        icon: DeleteOutlineOutlined,
                                        tooltip: 'Delete',
                                        iconProps: {
                                            color: 'primary',
                                        },
                                        onClick: () => this.openDeletePlayerDialog(rowData.playerGroup)
                                    }),
                                ]
                                : undefined
                        }
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getPlayers = async (query: any): Promise<QueryResult<any>> => {
        const { groupId } = this.props;
        if (!groupId) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`group/${groupId}/getPlayers`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.PlayerGroup) => {
            return {
                player: (
                    <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                        {e.avatarUrl === '' && (
                            <>
                                <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                                    <Circle style={{ color: e.online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                                    {getPlayerLink(e.playerId)}
                                </Grid>
                                <Grid size={12}>
                                    {e.displayName}
                                </Grid>
                            </>
                        )}
                        {e.avatarUrl !== '' && (
                            <>
                                <Grid size={2}>
                                    <CardMedia image={e.avatarUrl} style={{width: 40, height: 40, borderRadius: 4, border: `1px solid #c3cfdd`}}/>
                                </Grid>
                                <Grid size={10}>
                                    <Grid container={true} justifyContent="center" spacing={0}>
                                        <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                                            <Circle style={{ color: e.online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                                            {getPlayerLink(e.playerId)}
                                        </Grid>
                                        <Grid size={12}>
                                            {e.displayName}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ),
                state: (
                    <div>
                        <div>
                            <div style={{ fontWeight: 500 }}>State</div>
                            {PlayerGroupState[e.state].replace(/([A-Z])/g, ' $1').trim()}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>Created</div>
                            {formatDate(e.created)}
                        </div>
                        <div>
                            <div style={{ fontWeight: 500 }}>Updated</div>
                            {formatDate(e.updated)}
                        </div>
                    </div>
                ),
                metadata: parseJson(e.metadata, this.onMetadataSelected),
                playerGroup: e,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private renderHistory = () => {
        const { details, userTitleRole } = this.props;
        if (!details) {
            return;
        }

        const columns: any[] = [
            { title: 'Player', field: 'player' },
            { title: 'Type', field: 'type'},
            { title: 'Created', field: 'created'},
            { title: 'Data', field: 'data'},
        ];

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    History
                </StyledAccordionSummary>
                <StyledAccordionDetails style={{ padding: 0 }}>
                    <Table
                        data={this.getHistories}
                        columns={columns}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 30, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: true,
                            draggable: false,
                            actionsColumnIndex: -1,
                        }}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getHistories = async (query: any): Promise<QueryResult<any>> => {
        const { groupId } = this.props;
        if (!groupId) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const { page, pageSize, orderBy, orderDirection } = query;
        const params = {
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`group/${groupId}/getHistory`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.GroupHistory) => {
            return {
                player: (
                    <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                        {e.playerAvatarUrl === '' && (
                            <>
                                <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                                    <Circle style={{ color: e.playerOnline ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                                    {getPlayerLink(e.playerId)}
                                </Grid>
                                <Grid size={12}>
                                    {e.playerDisplayName}
                                </Grid>
                            </>
                        )}
                        {e.playerAvatarUrl !== '' && (
                            <>
                                <Grid size={2}>
                                    <CardMedia image={e.playerAvatarUrl} style={{width: 40, height: 40, borderRadius: 4, border: `1px solid #c3cfdd`}}/>
                                </Grid>
                                <Grid size={10}>
                                    <Grid container={true} justifyContent="center" spacing={0}>
                                        <Grid size={12} style={{ display: 'flex', alignContent: 'flex-start' }}>
                                            <Circle style={{ color: e.playerOnline ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                                            {getPlayerLink(e.playerId)}
                                        </Grid>
                                        <Grid size={12}>
                                            {e.playerDisplayName}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ),
                type: GroupHistoryType[e.type].replace(/([A-Z])/g, ' $1').trim(),
                created: formatDate(e.created),
                data: parseJson(e.data, this.onMetadataSelected),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private onMetadataSelected = (metadataSelected: string) => {
        if (metadataSelected !== '') {
            this.setState({ openDialog: 'Metadata', metadataSelected });
        }
    };
    private renderMetadataDialog = () => {
        const { openDialog, metadataSelected } = this.state;
        return (
            <JsonDialog
                open={openDialog === 'Metadata'}
                title="Metadata"
                value={metadataSelected}
                onClose={this.closeDialog}
            />
        );
    };

    private openAddPlayerDialog = () => this.setState({ openDialog: 'AddPlayer'})

    private openDeletePlayerDialog = (selectedPlayerGroup: Entities.PlayerGroup) => this.setState({openDialog: 'DeletePlayer', selectedPlayerGroup });
    private renderConfirmDeletePlayerDialog = () => {
        const { openDialog} = this.state;
        return (
            <WarningDialog
                open={openDialog === 'DeletePlayer'}
                title={'Confirm Delete'}
                TitleIcon={GroupWorkIcon}
                content="This will permanently delete this player from the group."
                onClose={this.closeDialog}
                onSubmit={this.deletePlayer}
                maxWidth={'xs'}
            />
        );
    };
    private deletePlayer = async () => {
        const { selectedPlayerGroup } = this.state;
        this.closeDialog();
        if (!selectedPlayerGroup) {
            return;
        }
        await actions.group.removePlayer(this.props.groupId, selectedPlayerGroup.playerId);
    };

    private openApprovePlayerApplicationDialog = (selectedPlayerGroup: Entities.PlayerGroup) => this.setState({openDialog: 'ApproveApplicant', selectedPlayerGroup });
    private renderApprovePlayerApplicationDialog = () => {
        const { openDialog} = this.state;
        return (
            <WarningDialog
                open={openDialog === 'ApproveApplicant'}
                title={'Approve Applicant'}
                TitleIcon={GroupWorkIcon}
                content="Do you want to approve the player application."
                onClose={this.closeDialog}
                onSubmit={this.approvePlayerApplication}
                maxWidth={'xs'}
            />
        );
    };
    private approvePlayerApplication = async () => {
        const { selectedPlayerGroup } = this.state;
        if (!selectedPlayerGroup) {
            return;
        }
        await actions.group.approveApplicant(this.props.groupId, selectedPlayerGroup.playerId);
    };

    private openRejectPlayerApplicationDialog = (selectedPlayerGroup: Entities.PlayerGroup) => this.setState({openDialog: 'RejectApplicant', selectedPlayerGroup });
    private renderRejectPlayerApplicationDialog = () => {
        const { openDialog} = this.state;
        return (
            <WarningDialog
                open={openDialog === 'RejectApplicant'}
                title={'Reject Applicant'}
                TitleIcon={GroupWorkIcon}
                content="Do you want to reject the player application."
                onClose={this.closeDialog}
                onSubmit={this.rejectPlayerApplication}
                maxWidth={'xs'}
            />
        );
    };
    private rejectPlayerApplication = async () => {
        const { selectedPlayerGroup } = this.state;
        if (!selectedPlayerGroup) {
            return;
        }
        await actions.group.rejectApplicant(this.props.groupId, selectedPlayerGroup.playerId);
    };

    private openChangeStatePlayerDialog = (selectedPlayerGroup: Entities.PlayerGroup) => this.setState({openDialog: 'ChangePlayerState', selectedPlayerGroup });
    private renderChangeStatePlayerDialog = () => {
        const { openDialog, selectedPlayerGroup} = this.state;
        if (!selectedPlayerGroup) {
            return;
        }
        return (
            <ChangeStateGroupPlayerDialog
                open={openDialog === 'ChangePlayerState'}
                title={'Change Player State'}
                TitleIcon={GroupWorkIcon}
                state={selectedPlayerGroup.state}
                onClose={this.closeDialog}
                onUpdate={this.changeStatePlayer}
                maxWidth={'xs'}
            />
        );
    };
    private changeStatePlayer = async (state: PlayerGroupState) => {
        const { selectedPlayerGroup } = this.state;
        if (!selectedPlayerGroup) {
            return;
        }
        if (selectedPlayerGroup.state === state) {
            return;
        }
        if (selectedPlayerGroup.state > state) {
            await actions.group.demotePlayer(this.props.groupId, selectedPlayerGroup.playerId, state);
        } else {
            await actions.group.promotePlayer(this.props.groupId, selectedPlayerGroup.playerId, state);
        }
    };

    private closeDialog = () =>
        this.setState({
            openDialog: null,
            metadataSelected: '',
            selectedPlayerGroup: null,
        });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    groupId: '',
    details: state.group.details,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const groupId = params.groupId || '';
    return (<Container {...props} navigate={navigate} groupId={groupId}/>);
};
export default connect(mapStateToProps)(AppContainer);
