import { CostumerSupportActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState } from '../reducers';

export const listCompensationPreDefineItems = async (): Promise<void> => {
    const preDefinedItems = await Request.get(`costumerSupport/compensationPreDefineItems/list`);
    dispatch({
        type: CostumerSupportActionType.Update,
        payload: {
            preDefinedItems,
        },
    });
};

export const createCompensationPreDefineItem = async (name: string, key: string, amount: number): Promise<void> => {
    const item = await Request.post(`costumerSupport/compensationPreDefineItems/create`, { name, key, amount });
    const preDefinedItems = getState().costumerSupport.preDefinedItems;
    dispatch({
        type: CostumerSupportActionType.Update,
        payload: {
            preDefinedItems: [ ...preDefinedItems, item],
        },
    });
};

export const updateCompensationPreDefineItem = async (id: string, name: string, key: string, amount: number): Promise<void> => {
    const item = await Request.post(`costumerSupport/compensationPreDefineItems/update/${id}`, { name, key, amount });
    const items = getState().costumerSupport.preDefinedItems;
    dispatch({
        type: CostumerSupportActionType.Update,
        payload: {
            preDefinedItems: items.map(i => i.id === id ? item : i)
        },
    });
};

export const deleteCompensationPreDefineItem = async (id: string): Promise<void> => {
    await Request.post(`costumerSupport/compensationPreDefineItems/delete/${id}`);
    const items = getState().costumerSupport.preDefinedItems;
    dispatch({
        type: CostumerSupportActionType.Update,
        payload: {
            preDefinedItems: items.filter(i => i.id !== id)
        },
    });
};
