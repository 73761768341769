import { LocalStorageActionType } from '../enums';
import { dispatch } from '../reducers';

const KEY = 'storage';

export const get = (): void => {
    const str = localStorage.getItem(KEY);
    const obj = str ? JSON.parse(str) : {};
    dispatch({
        type: LocalStorageActionType.Update,
        payload: obj,
    });
};

export const set = (key: keyof Entities.LocalStorage, data: Entities.LocalStorage[keyof Entities.LocalStorage]): void => {
    const str = localStorage.getItem(KEY);
    const obj = str ? JSON.parse(str) : {};
    obj[key] = { ...obj[key], ...data };
    localStorage.setItem(KEY, JSON.stringify(obj));
    dispatch({
        type: LocalStorageActionType.Update,
        payload: obj,
    });
};
