import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionSummaryProps,
    Chip,
    Grid2 as Grid,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import { Button, Dropzone, LabelCheckBox, Metadata, TextField, NumberField } from '../common';
import { MobileDatePicker as DatePicker, MobileTimePicker as TimePicker } from '@mui/x-date-pickers';
import {
    DurationMenuType,
    getDurationMenus,
    getDurationValueFromTimeStamp,
    isActive,
    Menu,
} from '../../utils';
import { default as moment, Moment } from 'moment';
import { AccessTime, AddOutlined, ArrowForwardIosSharp } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { SelectSegmentDialog } from '../segment';

interface Props {
    readonly type: 'Create' | 'Edit';
    readonly items: Entities.EconomyItem[];
    readonly onCreate?: (displayName: string, metadata: string, productId: string, purchaseLimit: number, start: number | null, end: number | null, segmentIds: string[], items: string[], virtualCurrencyPrices: string | null) => void;
    readonly onEdit?: (id: string, displayName: string, metadata: string, productId: string, purchaseLimit: number, start: number | null, end: number | null, selectedSegmentIds: string[], items: string[], virtualCurrencyPrices: string | null) => void;
    readonly onCancel: () => void;
    readonly item?: Entities.EconomyStoreItem;
    readonly segments: Entities.Segment[];
    readonly currencies: Entities.Currency[];
}

interface State {
    readonly selectSegmentDialogOpen: boolean;
    readonly id: string;
    readonly displayName: string;
    readonly metadata: string;
    readonly purchaseLimit: number;
    readonly items: string[];
    readonly file: File | null;
    readonly hasTimeLimited: boolean;
    readonly start: Moment;
    readonly end: Moment;
    readonly duration: number;
    readonly useDuration: boolean;
    readonly timesMenus: Record<DurationMenuType, Menu>;
    readonly isRealMoney: boolean;
    readonly productId: string;
    readonly virtualCurrencyPrices: string;
    readonly selectedSegmentIds: string[];
}

export class CreateEconomyStoreItemComponent extends React.Component<Props, State> {
    state = this.initState();

    componentDidMount() {
        const { item } = this.props;
        if (!item) {
            return;
        }
        this.setState(this.initStateFromItem(item));
    }

    componentDidUpdate(prevProps: Props) {
        const { item } = this.props;
        if (!item || !prevProps.item || item.id === prevProps.item.id) {
            return;
        }

        this.setState(this.initStateFromItem(item));
    }

    render() {
        const { type, items, segments } = this.props;
        const { displayName, metadata, hasTimeLimited, start, end, useDuration, duration, timesMenus, isRealMoney, productId, purchaseLimit, selectedSegmentIds, selectSegmentDialogOpen } = this.state;

        const durationViews: React.JSX.Element[] = [];
        Object.keys(timesMenus).forEach(key => {
            const timesMenu = key as DurationMenuType;
            const {title, value} = timesMenus[timesMenu];
            durationViews.push(
                <MenuItem key={key} value={value}>
                    {title}
                </MenuItem>
            );
        });

        const segmentElements: React.JSX.Element[] = [];
        selectedSegmentIds.forEach(segmentId => {
            const segment = segments.find(s => s.id === segmentId);
            if (!segment) {
                return;
            }
            const onRemoveSelected = () => this.setState({ selectedSegmentIds: selectedSegmentIds.filter(id => id !== segmentId)});
            segmentElements.push(
                <Grid size={{ xs:12, sm:6, md:4, lg:3 }}>
                    <Chip
                        color={'secondary'}
                        label={segment.name}
                        style={{ width: '100%', backgroundColor: segment.color, color: 'white' }}
                        onDelete={onRemoveSelected}
                    />
                </Grid>
            );
        });

        const availableToSelectSegments = segments.filter(s => !selectedSegmentIds.some(id => s.id === id));

        return (
            <>
                <Grid container={true} spacing={3}>
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary>
                                PROPERTIES
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Grid container={true} spacing={0}>
                                    <Grid size={12}>
                                        <InputLabel>Display Name</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            label=""
                                            value={displayName}
                                            variant="outlined"
                                            fullWidth={true}
                                            onChange={this.onChangeDisplayName}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Image</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <Dropzone
                                            accept={{
                                                "image/jpeg": ['.jpeg'],
                                                "image/jpg": ['.jpg'],
                                                "image/png": ['.png'],
                                            }}
                                            multiple={false}
                                            text={'Drop image file'}
                                            onDropFile={this.handleDrop}
                                            description={'Upload image (JPEG, JPG or PNG)'}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Purchase Limit</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <NumberField
                                            label=""
                                            value={purchaseLimit}
                                            variant="outlined"
                                            fullWidth={true}
                                            onChange={this.onChangePurchaseLimit}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Metadata</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <Metadata
                                            text={metadata}
                                            onChange={this.onChangeMetadata}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary>
                                PRICE
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Grid container={true} spacing={0}>
                                    <Grid size={12}>
                                        <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                                            <Typography variant="caption" style={{margin: 'auto'}}>Virtual Currency</Typography>
                                            <Switch
                                                checked={isRealMoney}
                                                onChange={this.changeIsRealMoney}
                                                color="primary"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            <Typography variant="caption" style={{margin: 'auto'}}>Real Money</Typography>
                                        </div>
                                    </Grid>
                                    {isRealMoney && (
                                        <>
                                            <Grid size={12}>
                                                <InputLabel>Store Product ID</InputLabel>
                                            </Grid>
                                            <Grid size={12}>
                                                <TextField
                                                    label=""
                                                    value={productId}
                                                    variant="outlined"
                                                    fullWidth={true}
                                                    onChange={this.onChangeProductId}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {!isRealMoney && (
                                        <Grid size={{ xs:12 }} />
                                    )}
                                </Grid>
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                    {segments.length > 0 && (
                        <Grid size={12}>
                            <StyledAccordion defaultExpanded={true}>
                                <StyledAccordionSummary>
                                    SEGMENTATION
                                </StyledAccordionSummary>
                                <StyledAccordionDetails>
                                    <Grid container={true} justifyContent="center" spacing={2}>
                                        <Grid size={12}>
                                            <StyledButton text="Add Segment" icon={AddOutlined} onClick={this.openSelectSegmentDialog} disabled={availableToSelectSegments.length === 0}/>
                                        </Grid>
                                        {segmentElements}
                                    </Grid>
                                </StyledAccordionDetails>
                            </StyledAccordion>
                        </Grid>
                    )}
                    {(type !== 'Edit' || !isActive(start.valueOf(), end.valueOf())) && (
                        <Grid size={12}>
                            <StyledAccordion defaultExpanded={true}>
                                <StyledAccordionSummary>
                                    SCHEDULE
                                </StyledAccordionSummary>
                                <StyledAccordionDetails>
                                    <Grid container={true} spacing={1}>
                                        <Grid size={12}>
                                            <LabelCheckBox
                                                label="Is Time Limited"
                                                icon={AccessTime}
                                                labelPlacement={'end'}
                                                checked={hasTimeLimited}
                                                justifyContent={'flex-start'}
                                                color={'#00000099'}
                                                iconColor={'#00000099'}
                                                onChange={this.handleHasTimeLimited}
                                            />
                                        </Grid>
                                        {hasTimeLimited && (
                                            <>
                                                <Grid size={12}>
                                                    <InputLabel>Start Date</InputLabel>
                                                </Grid>
                                                <Grid size={12}>
                                                    <DatePicker
                                                        value={start}
                                                        onChange={this.handleStartDateChange}
                                                    />
                                                </Grid>
                                                <Grid size={12}>
                                                    <InputLabel>Start Time</InputLabel>
                                                </Grid>
                                                <Grid size={12}>
                                                    <TimePicker
                                                        label=""
                                                        value={start}
                                                        onChange={this.handleStartDateChange}
                                                        ampm={false}
                                                    />
                                                </Grid>
                                                {useDuration && (
                                                    <>
                                                        <Grid size={12}>
                                                            <InputLabel>Duration</InputLabel>
                                                        </Grid>
                                                        <Grid size={12}>
                                                            <Select
                                                                value={duration}
                                                                onChange={this.handleChangeDuration}
                                                                style={{ width: '100%', backgroundColor: 'white' }}
                                                                variant={'outlined'}
                                                            >
                                                                {durationViews}
                                                            </Select>
                                                        </Grid>
                                                    </>
                                                )}
                                                {!useDuration && (
                                                    <>
                                                        <Grid size={12}>
                                                            <InputLabel>End Date</InputLabel>
                                                        </Grid>
                                                        <Grid size={12}>
                                                            <DatePicker
                                                                value={end}
                                                                onChange={this.handleEndDateChange}
                                                            />
                                                        </Grid>
                                                        <Grid size={12}>
                                                            <InputLabel>End Time</InputLabel>
                                                        </Grid>
                                                        <Grid size={12}>
                                                            <TimePicker
                                                                label=""
                                                                value={end}
                                                                onChange={this.handleEndDateChange}
                                                                ampm={false}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid size={12}>
                                                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                                                        <Typography variant="caption" style={{margin: 'auto'}}>Use Set Time</Typography>
                                                        <Switch
                                                            checked={useDuration}
                                                            onChange={this.changeEndTimeMode}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                        <Typography variant="caption" style={{margin: 'auto'}}>Use Duration</Typography>
                                                    </div>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </StyledAccordionDetails>
                            </StyledAccordion>
                        </Grid>
                    )}
                    {items.length > 0 && (
                        <Grid size={12}>
                            <StyledAccordion defaultExpanded={true}>
                                <StyledAccordionSummary>
                                    ITEMS
                                </StyledAccordionSummary>
                                <StyledAccordionDetails />
                            </StyledAccordion>
                        </Grid>
                    )}
                    <Grid size={12}>
                        <div style={{
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gridColumnGap: 20,
                            justifyContent: 'flex-start'
                        }}>
                            <Button
                                text={`${type} Item`}
                                variant="contained"
                                onClick={this.onCreate}
                                disabled={!this.validate()}
                            />
                            <Button
                                text="Cancel"
                                variant="contained"
                                color="secondary"
                                onClick={this.onCancel}
                            />
                        </div>
                    </Grid>
                </Grid>
                <SelectSegmentDialog
                    segments={availableToSelectSegments}
                    onSelectSegment={this.onSelectSegment}
                    title={"Select Segments"}
                    onClose={this.closeSelectSegmentDialog}
                    open={selectSegmentDialogOpen}
                />
            </>
        );
    }

    private initState(): State {
        const timesMenus = getDurationMenus();
        return {
            id: '',
            displayName: '',
            metadata: '',
            purchaseLimit: 0,
            items: [],
            file: null,
            hasTimeLimited: false,
            start: moment.parseZone(),
            end: moment.parseZone().add(1, 'day'),
            duration: timesMenus['1day'].value,
            useDuration: true,
            timesMenus,
            isRealMoney: true,
            productId: '',
            virtualCurrencyPrices: '',
            selectedSegmentIds: [],
            selectSegmentDialogOpen: false,
        };
    }

    private initStateFromItem(item: Entities.EconomyStoreItem): State {
        const { type } = this.props;
        const {
            id,
            displayName,
            metadata,
            productId,
            purchaseLimit,
            items,
            virtualCurrencyPrices,
            startTimestamp,
            endTimestamp,
            segmentIds,
        } = item;

        const timesMenus = getDurationMenus();
        const start = type === 'Edit' && startTimestamp !== null ? moment(startTimestamp) : moment.parseZone();
        const end = type === 'Edit' && endTimestamp !== null ? moment(endTimestamp) : moment.parseZone().add(1, 'day');
        const duration = type === 'Edit' && startTimestamp !== null && endTimestamp !== null ? getDurationValueFromTimeStamp(startTimestamp, endTimestamp) : timesMenus['1day'].value;
        const selectedSegmentIds = type === 'Edit' ? segmentIds : [];

        return {
            id,
            displayName: displayName || '',
            metadata: metadata || '',
            purchaseLimit: purchaseLimit || 0,
            items: items || [],
            file: null,
            hasTimeLimited: startTimestamp !== null && endTimestamp !== null,
            start,
            end,
            duration,
            useDuration: true,
            timesMenus,
            isRealMoney: productId !== '',
            productId: productId || '',
            virtualCurrencyPrices: virtualCurrencyPrices || '',
            selectedSegmentIds,
            selectSegmentDialogOpen: false,
        };
    }

    private onCreate = async () => {
        const { type, onCreate, onEdit } = this.props;
        const { id, displayName, metadata, productId, purchaseLimit, items, isRealMoney, virtualCurrencyPrices, hasTimeLimited, start, end, useDuration, duration, selectedSegmentIds } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        const startTimestamp = hasTimeLimited ? start.valueOf() : null;
        const endTimestamp =  hasTimeLimited ? useDuration ? start.add(duration, 'minutes').valueOf() : end.valueOf() : null;
        const virtualCurrencyPricesData = isRealMoney ? null : virtualCurrencyPrices;

        if (type === 'Create' && onCreate !== undefined) {
            onCreate(displayName, metadata, productId, purchaseLimit, startTimestamp, endTimestamp, selectedSegmentIds, items, virtualCurrencyPricesData);
        }
        else if (onEdit !== undefined) {
            onEdit(id, displayName, metadata, productId, purchaseLimit, startTimestamp, endTimestamp, selectedSegmentIds, items, virtualCurrencyPricesData);
        }
        this.setState(this.initState());
    };

    private onCancel = async () => {
        this.props.onCancel();
    };

    private validate = () => {
        return true;
    }

    private handleDrop = (file: File) => this.setState({ file });

    private handleChangeDuration = (event: SelectChangeEvent<number>) => {
        this.setState({...this.state, duration: event.target.value as number});
    };

    private onChangeMetadata = (metadata: string) => this.setState({ metadata });

    private onChangeDisplayName = (displayName: string) => this.setState({ displayName });

    private onChangePurchaseLimit = (purchaseLimit: number) => this.setState({ purchaseLimit });

    private onChangeProductId = (productId: string) => this.setState({ productId });

    protected handleStartDateChange = (value: Moment | null) => {
        if (!value) {
            return;
        }
        this.setState({ start: value });
    }

    protected handleEndDateChange = (end: Moment | null) =>  {
        if (!end) {
            return;
        }
        this.setState({ end });
    }

    protected changeEndTimeMode = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ useDuration: event.target.checked });

    protected handleHasTimeLimited = (hasTimeLimited: boolean) => this.setState({ hasTimeLimited });

    private changeIsRealMoney = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ isRealMoney: event.target.checked });

    private openSelectSegmentDialog = () => this.setState({ selectSegmentDialogOpen: true });

    private closeSelectSegmentDialog = () => this.setState({ selectSegmentDialogOpen: false });

    private onSelectSegment = (id: string) => this.setState( { selectedSegmentIds: [ ...this.state.selectedSegmentIds, id ], selectSegmentDialogOpen: false });
}

const StyledAccordion = styled(Accordion)(({
    '&.MuiAccordion-root': {
        width: '100%',
        boxShadow: 'none',
        borderRadius: 0,
        backgroundColor: 'transparent',
        padding: 0,
        border: 'none',
    },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    '&.MuiAccordionSummary-root': {
        minHeight: 40,
        padding: '0px 10px',
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
        fontWeight: 600,
        color: 'rgb(22, 25, 31)',
        fontSize: 13,
        marginLeft: 15,
    },
    backgroundColor: 'transparent',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({
    borderTop: '2px solid #dfdfdf',
    borderRadius: 0,
    padding: 10,
    fontSize: '14px',
    backgroundColor: 'transparent',
}));

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));
