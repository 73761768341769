import * as _ from 'lodash';
import { EconomyActionType } from '../enums';
import { Request } from '../utils';
import { dispatch, getState } from '../reducers';

export const getItem = async (itemId: string): Promise<void> => {
    const { items } = getState().economy;
    if (items.length === 0) {
        await getItems(itemId);
        return;
    }

    dispatch({
        type: EconomyActionType.Update,
        payload: {
            item: _.find(items, item => item.id === itemId),
        },
    });
};

export const getItems = async (selectedItemId?: string): Promise<void> => {
    const items: Entities.EconomyItem[] | null = await Request.get(`economy/items`);
    if (!items) {
        return;
    }
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            items,
            item: selectedItemId ? items.find(i => i.id === selectedItemId) : undefined,
        },
    });
};

export const createItem = async (
    itemId: string,
    itemClass: string,
    displayName: string,
    description: string,
    tags: string[],
    customData: string,
    isStackable: boolean,
    isTradable: boolean,
    isLimitedEdition: boolean,
): Promise<void> => {
    const data = {
        ItemId: itemId,
        ItemClass: itemClass,
        DisplayName: displayName,
        Description: description,
        Tags: tags,
        CustomData: customData,
        IsStackable: isStackable,
        IsTradable: isTradable,
        ItemImageUrl: '',
        IsLimitedEdition: isLimitedEdition,
        InitialLimitedEditionCount: 0,
    };
    const item = await Request.post(`economy/createItem`, data);
    const { items } = getState().economy;
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            items: [ ...items, item ],
        },
    });
};

export const updateItem = async (
    itemId: string,
    itemClass: string,
    displayName: string,
    description: string,
    tags: string[],
    customData: string,
    isStackable: boolean,
    isTradable: boolean,
    isLimitedEdition: boolean,
): Promise<void> => {
    const data = {
        ItemId: itemId,
        ItemClass: itemClass,
        DisplayName: displayName,
        Description: description,
        Tags: tags,
        CustomData: customData,
        IsStackable: isStackable,
        IsTradable: isTradable,
        ItemImageUrl: '',
        IsLimitedEdition: isLimitedEdition,
        InitialLimitedEditionCount: 0,
    };
    const item = await Request.post(`economy/updateItem`, data);
    const { items } = getState().economy;
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            items: items.map(i => i.id === itemId ? item : i),
        },
    });
};

export const deleteItem = async (itemId: string): Promise<void> => {
    await Request.post(`economy/${itemId}/delete`);
    const { items } = getState().economy;
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            items: items.filter(i => i.id !== itemId),
        },
    });
};

export const listCurrencies = async (): Promise<void> => {
    const currencies: Entities.Currency[] | null = await Request.get(`economy/currency/list`);
    if (!currencies) {
        return;
    }
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            currencies,
        },
    });
};

export const createCurrency = async (code: string, name: string): Promise<void> => {
    const currency: Entities.Currency | null = await Request.post(`economy/currency/create`, { code, name });
    if (!currency) {
        return;
    }

    const state = getState().economy;
    const currencies = [...state.currencies ? state.currencies : [], currency];
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            currencies,
        },
    });
};

export const removeCurrency = async (code: string): Promise<void> => {
    await Request.post(`economy/currency/remove`, { code });
    const state = getState().economy;
    const currencies = state.currencies ? state.currencies.filter(c => c.code !== code) : [];
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            currencies,
        },
    });
};

export const getCreateStoreData  = async (): Promise<void> => {
    const [ items, segments, currencies ] = await Promise.all([
        Request.get(`economy/items`),
        Request.get(`segment/list`),
        Request.get(`economy/currency/list`)
    ]);

    dispatch({
        type: EconomyActionType.Update,
        payload: {
            items,
            segments,
            currencies,
        },
    });
};

export const getStoreItems = async (selectedItemId?: string): Promise<void> => {
    const [ storeItems, segments ] = await Promise.all([
        Request.get<Entities.StoreItem[]>(`economy/store/items`),
        Request.get(`segment/list`),
    ]);
    if (!storeItems) {
        return;
    }
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            storeItems,
            storeItem: selectedItemId ? storeItems.find(i => i.id === selectedItemId) : undefined,
            segments,
        },
    });
};

export const getStoreItem = async (itemId: string): Promise<void> => {
    const [ storeItem, segments ] = await Promise.all([
        Request.get(`economy/store/item/${itemId}`),
        Request.get(`segment/list`),
    ]);

    dispatch({
        type: EconomyActionType.Update,
        payload: {
            storeItem,
            segments,
        },
    });
};

export const createStoreItem = async (
    displayName: string,
    metadata: string,
    productId: string,
    purchaseLimit: number,
    start: number | null,
    end: number | null,
    segmentIds: string[],
    items: string[],
    virtualCurrencyPrices: string | null,
): Promise<void> => {
    const storeItem = await Request.post(`economy/store/createItem`, { displayName, metadata, productId, purchaseLimit, start, end, items, virtualCurrencyPrices, segmentIds });
    const { storeItems } = getState().economy;
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            storeItems: [ ...storeItems, storeItem ],
        },
    });
};

export const editStoreItem = async (
    id: string,
    displayName: string,
    metadata: string,
    productId: string,
    purchaseLimit: number,
    start: number | null,
    end: number | null,
    segmentIds: string[],
    items: string[],
    virtualCurrencyPrices: string | null,
): Promise<void> => {
    const storeItem = await Request.post(`economy/store/editItem`, { id, displayName, metadata, productId, purchaseLimit, start, end, items, virtualCurrencyPrices, segmentIds });
    const { storeItems } = getState().economy;
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            storeItems: [ ...storeItems, storeItem ],
        },
    });
};

export const deleteStoreItem = async (
    itemId: string,
): Promise<void> => {
    await Request.post(`economy/store/${itemId}/delete`);
    const state = getState().economy;
    const storeItems = state.storeItems ? state.storeItems.filter(i => i.id !== itemId) : [];
    dispatch({
        type: EconomyActionType.Update,
        payload: {
            storeItems,
        },
    });
};
