import React from 'react';
import { Filter } from '../common';
import {
    CheckCircle,
    PlayArrow,
    RemoveCircle,
    Error as ErrorIcon,
} from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { FilterList, FilterListItem, FilterListItemButton, FilterListItemIcon, FilterListItemText } from '../../utils';

interface Props {
    readonly jobs: string[];
    readonly selectedJob: string;
    readonly successFilter: boolean;
    readonly buildingFilter: boolean;
    readonly canceledFilter: boolean;
    readonly failedFilter: boolean;
    readonly onFilter: (selectedJob: string, successFilter: boolean, buildingFilter: boolean, canceledFilter: boolean, failedFilter: boolean) => void;
}

interface State {
    readonly selectedJob: string;
    readonly successFilter: boolean;
    readonly buildingFilter: boolean;
    readonly canceledFilter: boolean;
    readonly failedFilter: boolean;
}

export class CloudBuildFilter extends React.Component<Props, State> {
    state: State = {
        selectedJob: '',
        successFilter: false,
        buildingFilter: false,
        canceledFilter: false,
        failedFilter: false,
    };

    componentDidUpdate(prevProps: Props) {
        const { selectedJob, successFilter, buildingFilter, canceledFilter, failedFilter } = this.props;
        if ( prevProps.selectedJob === selectedJob
            && prevProps.successFilter === successFilter
            && prevProps.buildingFilter === buildingFilter
            && prevProps.canceledFilter === canceledFilter
            && prevProps.failedFilter === failedFilter) {
            return;
        }

        this.setState({
            selectedJob,
            successFilter,
            buildingFilter,
            canceledFilter,
            failedFilter,
        });
    }

    render() {
        const { jobs } = this.props;
        const { selectedJob, successFilter, buildingFilter, canceledFilter, failedFilter } = this.state;

        const onFilter = () => {
            this.props.onFilter(selectedJob, successFilter, buildingFilter, canceledFilter, failedFilter);
        };

        const onClose = () => this.setState({
            selectedJob: this.props.selectedJob,
            successFilter: this.props.successFilter,
            buildingFilter: this.props.buildingFilter,
            canceledFilter: this.props.canceledFilter,
            failedFilter: this.props.failedFilter,
        });

        const onFilterSuccess = () => this.setState({ successFilter: true, buildingFilter: false, canceledFilter: false, failedFilter: false });
        const onFilterBuilding = () => this.setState({ successFilter: false, buildingFilter: true, canceledFilter: false, failedFilter: false });
        const onFilterCanceled = () => this.setState({ successFilter: false, buildingFilter: false, canceledFilter: true, failedFilter: false });
        const onFilterFailed = () => this.setState({ successFilter: false, buildingFilter: false, canceledFilter: false, failedFilter: true });

        return (
            <Filter
                title={'Filter Builds'}
                menuTitles = {['Target', 'Status']}
                menuElements = {[
                    (
                        <FilterList>
                            {jobs.map((job, index) => {
                                const selected = selectedJob === job;
                                const handleTypeFilterChange = () => this.setState({
                                    selectedJob: job,
                                });

                                return (
                                    <FilterListItemButton
                                        key={`cloud_build_job_filter_${index}`}
                                        selected={selected}
                                        onClick={handleTypeFilterChange}
                                    >
                                        <FilterListItemText text={job} />
                                    </FilterListItemButton>
                                );
                            })}
                        </FilterList>
                    ),
                    (
                        <FilterList>
                            <FilterListItem
                                key={`cloud_build_status_filter_success`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterSuccess}
                                        checked={successFilter}
                                    />
                                }
                            >
                                <FilterListItemIcon>
                                    <CheckCircle style={{ color: '#4eb709', width: 18, height: 18 }}/>
                                </FilterListItemIcon>
                                <FilterListItemText text={'Success'} />
                            </FilterListItem>
                            <FilterListItem
                                key={`cloud_build_status_filter_building`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterBuilding}
                                        checked={buildingFilter}
                                    />
                                }
                            >
                                <FilterListItemIcon>
                                    <PlayArrow style={{ color: '#0073bb', width: 18, height: 18 }}/>
                                </FilterListItemIcon>
                                <FilterListItemText text={'Building'} />
                            </FilterListItem>
                            <FilterListItem
                                key={`cloud_build_status_filter_canceled`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterCanceled}
                                        checked={canceledFilter}
                                    />
                                }
                            >
                                <FilterListItemIcon>
                                    <RemoveCircle style={{ color: '#e39f28', width: 18, height: 18 }}/>
                                </FilterListItemIcon>
                                <FilterListItemText text={'Canceled'} />
                            </FilterListItem>
                            <FilterListItem
                                key={`cloud_build_status_filter_failed`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterFailed}
                                        checked={failedFilter}
                                    />
                                }
                            >
                                <FilterListItemIcon>
                                    <ErrorIcon style={{ color: '#d20f08', width: 18, height: 18 }}/>
                                </FilterListItemIcon>
                                <FilterListItemText text={'Failed'} />
                            </FilterListItem>
                        </FilterList>
                    ),
                ]}
                onFilter={onFilter}
                onClose={onClose}
            />
        );
    }
}

