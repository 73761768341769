import {
    BaseContainer,
    BaseElement,
    HeaderButton,
    PromoCampaignFilter,
    Table,
    WarningDialog,
} from '../components';
import { ContainerContext, mapProps } from './index';
import { UserTitleRole } from '../enums';
import {
    AddOutlined,
    CardGiftcard as CardGiftcardIcon,
    DeleteOutlineOutlined,
    DeveloperModeOutlined,
} from '@mui/icons-material';
import * as React from 'react';
import { Chip, Grid2 as Grid } from '@mui/material';
import { formatDate, isActive, isComing, isEnded, Link, truncateString } from '../utils';
import actions, { ActionRequest } from '../actions';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import { styled } from '@mui/styles';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Delete' | 'ShowCalendar';
interface State {
    readonly developerOnly: boolean;
    readonly authAnchorEl: HTMLElement | undefined;
    readonly openDialog: DialogType | null;
    readonly selected: Entities.PromoCampaign | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        developerOnly: false,
        authAnchorEl: undefined,
        openDialog: null,
        selected: null,
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.createPromoCampaign},
        ];
        return (
            <BaseContainer
                {...this.props}
                title = "Promo Campaigns"
                TitleIcon = {CardGiftcardIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { loading, userTitleRole } = this.props;
        const { developerOnly } = this.state;

        const tableRef = React.createRef();

        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Schedule', field: 'schedule'},
        ];

        const onSearch = () => {
            // @ts-ignore
            tableRef.current.onSearchChangeDebounce();
        };

        const onResetDeveloper = () => {
            this.setState({ developerOnly: false });
            onSearch();
        };

        const onFilter = (developer: boolean) => {
            this.setState({ developerOnly: developer });
            onSearch();
        };

        const showChips = developerOnly;
        const title = (
            <Grid container={true} spacing={1}>
                <Grid size={12}>
                    <PromoCampaignFilter
                        developerOnly={developerOnly}
                        onFilter={onFilter}
                    />
                </Grid>
                {showChips &&
                    <Grid size={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gridColumnGap: 5, gridGap: 5}} >
                            {developerOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<DeveloperModeOutlined />}
                                    label='Player Type: "Developer only"'
                                    onDelete={onResetDeveloper}
                                />
                            )}
                        </div>
                    </Grid>
                }
            </Grid>
        );
        const onRowClick = (event: any, rowData: any) => this.toLink( `/marketing/promoCampaign/${rowData.promoCampaign.id}/details`);

        return (
            <Table
                tableRef={tableRef}
                isLoading = {loading}
                title = {title}
                columns = {columns}
                onRowClick={onRowClick}
                options = {{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    sorting: false,
                    draggable: false,
                    search: true,
                    actionsColumnIndex: -1,
                }}
                data = {this.getPromoCampaigns}
                actions = {
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.deletePromoCampaign(rowData.promoCampaign)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private getPromoCampaigns = async (query: any) => {
        const { developerOnly } = this.state;
        const { search, page, pageSize } = query;

        const list = await ActionRequest.get<Entities.ListPromoCampaignResponse>(`promoCampaign/listByPage`, {
            name: search,
            developerOnly,
            page: page + 1,
            perPage: pageSize,
        });


        if (!list) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const campaigns = list.campaigns
            .sort((e1, e2) => {
                const e1Active = isActive(e1.start, e1.end);
                const e2Active = isActive(e2.start, e2.end);
                const e1Coming = isComing(e1.start, e1.end);
                const e2Coming = isComing(e2.start, e2.end);
                if (e1Active && e2Coming) {
                    return -1;
                } else if (e2Active && e1Coming) {
                    return 1;
                } else if ((e1Active && !e2Active) || (e1Coming && !e2Coming)) {
                    return -1;
                } else if (!e1Active && e2Active || (!e1Coming && e2Coming)) {
                    return 1;
                }
                return e2.start - e1.start;
            });

        const data = campaigns.map(c => {
                const eventDetails = this.getDetails(c);
                const eventSchedule = this.getSchedule(c);
                return {
                    promoCampaign: c,
                    details: eventDetails,
                    schedule: eventSchedule,
                };
            }
        );
        return {
            data,
            page: list.page - 1,
            totalCount: list.totalCount,
        };
    };

    private getDetails = (promoCampaign: Entities.PromoCampaign) => {
        const to = `/marketing/promoCampaign/${promoCampaign.id}/details`;
        return (
            <div>
                {promoCampaign.developerOnly && (
                    <Chip
                        color={'secondary'}
                        icon={<DeveloperModeOutlined />}
                        size='small'
                        label='Developer Only'
                        style={{ backgroundColor: '#21BA47', color: 'white', margin: '2px 8px' }}
                    />
                )}
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'inline-block', marginLeft: 10, verticalAlign: 'top' }}>
                        <Link to={to}>
                            {promoCampaign.name !== '' ? truncateString(promoCampaign.name) : promoCampaign.id}
                        </Link>
                        <div>
                            <div style={{ fontWeight: 500 }}>Last Edited</div>
                            {formatDate(promoCampaign.lastUpdated)}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private getSchedule = (promoCampaign: Entities.PromoCampaign) => {
        return (
            <div>
                <div>
                    {this.getStatus(promoCampaign)}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>Start</div>
                    {formatDate(promoCampaign.start)}
                </div>
                <div>
                    <div style={{ fontWeight: 500 }}>End</div>
                    {formatDate(promoCampaign.end)}
                </div>
            </div>
        );
    };

    private getStatus = (promoCampaign: Entities.PromoCampaign) => {
        if (isActive(promoCampaign.start, promoCampaign.end)) {
            return <div style={{color: 'green', fontWeight: 500}}>In Progress</div>;
        }
        if (isEnded(promoCampaign.end)) {
            return <div style={{color: 'orange', fontWeight: 500}}>Completed</div>;
        }
        return <div style={{color: 'grey', fontWeight: 500}}>Scheduled</div>;
    };

    private createPromoCampaign = (event: any) => this.toLink( `/marketing/promoCampaign/create`);

    private deletePromoCampaign = (promoCampaign: Entities.PromoCampaign) => this.setState({openDialog: 'Delete', selected: promoCampaign});
    private renderDeleteDialog = () => {
        const { openDialog, selected } = this.state;
        if (!selected) {
            return;
        }
        const title = `Delete promo campaign '${selected ? selected.name : ''}'?`;

        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={title}
                TitleIcon={CardGiftcardIcon}
                content="This will permanently delete the promo campaign and all associated configuration."
                onClose={this.onCloseDialog}
                onSubmit={this.onDelete}
            />
        );
    };
    private onDelete = async () => {
        const { selected } = this.state;
        if (!selected) {
            return;
        }
        await actions.promoCampaign.remove(selected.id);
    };

    private onCloseDialog = () => this.setState({ openDialog: null, selected: null});
}

export const StyledChip = styled(Chip)(({
    backgroundColor: '#1a73e8',
    borderRadius: 0,
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
