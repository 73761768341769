import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import { BaseContainer, BaseElement, CreateNewLeaderBoardEventComponent } from '../components';
import { EventType, LeaderBoardType, ListEventFilterStateType } from '../enums';
import actions from '../actions';
import { connect } from 'react-redux';
import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly list?: Entities.LiveEvent[];
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        await actions.liveEvent.list(ListEventFilterStateType.ActiveAndComing);
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Live Event',
                to: `/liveEvent/all`,
            },
            {
                text: 'New Leaderboard',
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                title = {"New Live Event"}
                TitleIcon = {CalendarTodayIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { list } = this.props;
        if (!list) {
            return;
        }
        return (
            <CreateNewLeaderBoardEventComponent
                eventType={EventType.LeaderBoard}
                type='Create'
                liveEvents={list}
                onCreate={this.onCreateNewLeaderBoard}
            />
        );
    }

    private onCreateNewLeaderBoard = async (
        liveEvent: Entities.LiveEvent,
        ranks: Entities.EntityLeaderBoardRank[],
        maxUsersPerInstance: number,
        leaderBoardType: LeaderBoardType,
    ): Promise<void> => {
        await actions.liveEvent.createEventLeaderBoard(
            liveEvent,
            ranks,
            maxUsersPerInstance,
            leaderBoardType,
        );
        this.toLiveEvent();
    };

    private toLiveEvent = () => this.toLink(`/liveEvent/all`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    list: state.liveEvent.list,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
