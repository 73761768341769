import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Grid2 as Grid,
    InputLabel,
} from '@mui/material';
import {
    AddOutlined,
    CloudUploadOutlined,
    DeleteForeverOutlined,
    DeleteOutlined,
    ExpandLess,
    ExpandMore,
} from '@mui/icons-material';
import { Metadata, Button, TextField, ImportJsonDialog, NumberField } from '../common';
import { styled } from '@mui/styles';

interface Props {
    readonly tiers: Entities.SeasonTier[];
    readonly readOnly: boolean;
    readonly onUpdate?: (tiers: Entities.SeasonTier[]) => void;
}

type DialogType = 'Import';
interface State {
    readonly openDialog: DialogType | null;
    readonly tiers: Entities.SeasonTier[];
    readonly opens: boolean[];
}

export class SeasonTiers extends React.Component<Props, State> {
    state: State = {
        openDialog: null,
        tiers: [],
        opens: [],
    };

    async componentDidMount() {
        const { tiers } = this.props;
        if (tiers) {
            this.setState({tiers});
        }
    }

    render() {
        const { readOnly } = this.props;
        const { tiers, opens } = this.state;
        const tiersElements: React.JSX.Element[] = [];
        tiers.forEach((t, i) => tiersElements.push(this.renderSeasonTier(t, opens[i], i, tiers.length - 1 === i)));

        return (
            <>
                <Grid container={true} justifyContent="center" spacing={0}>
                    {!readOnly && (
                        <>
                            <Grid size={12}>
                                <StyledButton
                                    text="Add Tier"
                                    icon={AddOutlined}
                                    onClick={this.addTier}
                                />
                                <StyledButton
                                    style={{ minWidth: 0 }}
                                    icon={ExpandMore}
                                    text={`Open all`}
                                    onClick={this.expandAll}
                                    disabled={opens.every(o => o)}
                                />
                                <StyledButton
                                    style={{ minWidth: 0 }}
                                    icon={ExpandLess}
                                    text={`Close All`}
                                    onClick={this.closeAll}
                                    disabled={opens.every(o => !o)}
                                />
                                {tiers.length > 0 && (
                                    <StyledButton
                                        style={{ minWidth: 0 }}
                                        icon={DeleteOutlined}
                                        text={`Remove All`}
                                        onClick={this.removeAll}
                                    />
                                )}
                            </Grid>
                            <Grid size={12} style={{ margin: '10px 0px' }}>
                                <StyledDivider />
                            </Grid>
                        </>
                    )}
                    <Grid size={12}>
                        <Grid container={true} justifyContent="center" spacing={1} style={{ padding: '5px 20px' }}>
                            {tiersElements}
                            {tiers.length === 0 && (
                                <>
                                    <StyledButton
                                        style={{ minWidth: 0 }}
                                        icon={CloudUploadOutlined}
                                        text={`Upload from file`}
                                        onClick={this.openImportDialog}
                                    />
                                    <Grid size={12} style={{ textAlign: 'center' }}>
                                        No Tiers
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {!readOnly && (
                        <Grid size={12} style={{ margin: '10px 0px' }}>
                            <StyledDivider />
                        </Grid>
                    )}
                    {!readOnly && tiers.length > 0 && (
                        <>
                            <Grid size={12}>
                                <StyledButton
                                    text="Add Tier"
                                    icon={AddOutlined}
                                    onClick={this.addTier}
                                />
                                <StyledButton
                                    style={{ minWidth: 0 }}
                                    icon={ExpandMore}
                                    text={`Open all`}
                                    onClick={this.expandAll}
                                    disabled={opens.every(o => o)}
                                />
                                <StyledButton
                                    style={{ minWidth: 0 }}
                                    icon={ExpandLess}
                                    text={`Close All`}
                                    onClick={this.closeAll}
                                    disabled={opens.every(o => !o)}
                                />
                                {tiers.length > 0 && (
                                    <StyledButton
                                        style={{ minWidth: 0 }}
                                        icon={DeleteOutlined}
                                        text={`Remove All`}
                                        onClick={this.removeAll}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
                {this.renderImportDialog()}
            </>
        );
    }

    private addTier = () => {
        const { tiers, opens } = this.state;
        const tier: Entities.SeasonTier = {
            id: '',
            target: 0,
            order: tiers.length + 1,
            name: '',
            metadata: '{\n\t\n}',
            rewards: [],
        };
        tiers.push(tier);
        opens.push(true);
        this.setState({ opens });
        this.onChangeTiers(tiers);
    }

    private renderSeasonTier = (tier: Entities.SeasonTier, open: boolean, index: number, last: boolean) => {
        const { readOnly } = this.props;
        const { tiers, opens } = this.state;

        const onChangeTarget = (target: number) => {
            tiers[index].target = target;
            this.onChangeTiers(tiers);
        };

        const onChangeName = (name: string) => {
            tiers[index].name = name;
            this.onChangeTiers(tiers);
        };

        const onChangeMetadata = (metadata: string) => {
            tiers[index].metadata = metadata;
            this.onChangeTiers(tiers);
        };

        const onRemove = () => {
            this.setState({ opens: opens.filter((t, i) => i !== index) });
            this.onChangeTiers(tiers.filter((t, i) => i !== index));
        };

        const onExpandChange = (event: object, expanded: boolean) => {
            this.setState({ opens: opens.map((t, i) => i === index ? expanded : t) });
        };

        const addReward = () => {
            tiers[index].rewards.push({
                id: 0,
                key: '',
                amount: 0,
            });
            this.onChangeTiers(tiers);
        };

        const rewardsViews: React.JSX.Element[] = [];
        tier.rewards.forEach((t, i) => rewardsViews.push(this.renderSeasonTierReward(index, i, t)));

        return (
            <>
                {index > 0 && (
                    <Grid size={12}>
                        <StyledDivider />
                    </Grid>
                )}
                <Grid size={12}>
                    <StyledAccordion expanded={open} onChange={onExpandChange}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Tier {index+1}
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} spacing={0} style={{ padding: 10, width: '100%', margin: -1 }}>
                                <Grid size={12}>
                                    <InputLabel>Name</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        label=""
                                        value={tier.name}
                                        variant="outlined"
                                        required={true}
                                        onChange={onChangeName}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Target</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <NumberField
                                        fullWidth={true}
                                        label=""
                                        value={tier.target}
                                        variant="outlined"
                                        required={true}
                                        onChange={onChangeTarget}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid size={12} style={{ margin: '10px 0px' }}>
                                    <StyledDivider />
                                </Grid>
                                <Grid size={12}>
                                    <StyledAccordion square={true} defaultExpanded={!readOnly}>
                                        <StyledAccordionSummary expandIcon={<ExpandMore />} style={{minHeight: 30}}>
                                            Metadata
                                        </StyledAccordionSummary>
                                        <StyledAccordionDetails>
                                            <Grid container={true} spacing={0}>
                                                <Grid size={12}>
                                                    <Metadata
                                                        text={tier.metadata}
                                                        showBox={false}
                                                        readOnly={readOnly}
                                                        onChange={onChangeMetadata}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </StyledAccordionDetails>
                                    </StyledAccordion>
                                </Grid>
                                <Grid size={12} style={{ margin: '10px 0px' }}>
                                    <StyledDivider />
                                </Grid>
                                <Grid size={12}>
                                    <StyledAccordion square={true} defaultExpanded={!readOnly || tier.rewards.length > 0}>
                                        <StyledAccordionSummary expandIcon={<ExpandMore />} style={{minHeight: 30}}>
                                            Rewards ({tier.rewards.length})
                                        </StyledAccordionSummary>
                                        <StyledAccordionDetails>
                                            <Grid container={true} spacing={1}>
                                                <Grid size={12}>
                                                    <Grid container={true} spacing={0} style={{ padding: 10 }}>
                                                        {rewardsViews}
                                                    </Grid>
                                                </Grid>
                                                {!readOnly && (
                                                    <Grid size={12} style={{display: 'grid', justifyContent: 'end', padding: 0}}>
                                                        <StyledButton text="Add Reward" icon={AddOutlined} onClick={addReward} />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </StyledAccordionDetails>
                                    </StyledAccordion>
                                </Grid>
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {!readOnly && (
                    <Grid size={12}>
                        <div style={{display: 'grid', justifyContent: 'end', padding: 0}}>
                            <StyledButton text="Remove tier" icon={DeleteOutlined} onClick={onRemove} />
                        </div>
                    </Grid>
                )}
            </>
        );
    };

    private renderSeasonTierReward = (tierIndex: number, index: number, reward: Entities.SeasonTierReward) => {
        const { readOnly } = this.props;
        const { tiers } = this.state;

        const onUpdateKey = (key: string) => {
            tiers[tierIndex].rewards[index].key = key;
            this.onChangeTiers(tiers);
        };

        const onUpdateAmount = (amount: number) => {
            tiers[tierIndex].rewards[index].amount = amount;
            this.onChangeTiers(tiers);
        };

        const onRemove = () => {
            tiers[tierIndex].rewards = tiers[tierIndex].rewards.filter((r, i) => i !== index);
            this.onChangeTiers(tiers);
        };

        return (
            <>
                <Grid size={12}>
                    <InputLabel >Key</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        value={reward.key}
                        variant="outlined"
                        required={true}
                        onChange={onUpdateKey}
                        disabled={readOnly}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Amount</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        fullWidth={true}
                        label=""
                        value={reward.amount}
                        variant="outlined"
                        required={true}
                        onChange={onUpdateAmount}
                        disabled={readOnly}
                    />
                </Grid>
                {!readOnly && (
                    <Grid size={12}>
                        <StyledButton text="Delete" icon={DeleteForeverOutlined} variant={'text'} onClick={onRemove} />
                    </Grid>
                )}
            </>
        );
    };

    private onChangeTiers = (tiers: Entities.SeasonTier[]) => {
        const { onUpdate } = this.props;
        if(!onUpdate) {
            return;
        }
        this.setState({ tiers });
        onUpdate(tiers);
    };

    private openImportDialog = () => this.setState({ openDialog: 'Import' });
    private renderImportDialog = () => {
        const { openDialog } = this.state;
        return (
            <ImportJsonDialog
                open={openDialog === 'Import'}
                title="Import Tiers"
                content="Import the tiers data from JSON file."
                dropzoneText={'Drop exported tiers data.'}
                onClose={this.closeDialog}
                onImport={this.importTiers}
            />
        );
    };

    private closeDialog = () =>
        this.setState({
            openDialog: null,
        });

    private importTiers = async (data: string) => {
        const opens: boolean[] = [];
        const tiers: Entities.SeasonTier[] = JSON.parse(data).map((d: Entities.SeasonTier) => {
            opens.push(false);
            return {
                id: d.id,
                target: d.target,
                order: d.order,
                name: d.name,
                metadata: d.metadata,
                rewards: d.rewards,
            };
        });
        this.setState({ openDialog: null, opens });
        this.onChangeTiers(tiers);
    };

    private expandAll = () => {
        const opens = this.state.opens.map( s => true);
        this.setState({ opens });
    }

    private closeAll = () => {
        const opens = this.state.opens.map( s => false);
        this.setState({ opens });
    }

    private removeAll = () => {
        this.setState({ tiers: [] });
    }
}

const StyledAccordion = styled(Accordion)(({
    '&.MuiAccordion-root': {
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    '& .MuiAccordion-expanded': {
        minHeight: 30,
    },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({
    '&.MuiAccordionSummary-root': {
        padding: 0,
        minHeight: 30,
        height: 0,
        display: 'inline-flex',
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
        fontWeight: 'bold',
        color: 'rgb(22, 25, 31)',
        fontSize: '1rem',
    },
    padding: 0,
}));

const StyledAccordionDetails = styled(AccordionDetails)(({
    '&.MuiAccordionDetails-root': {
        padding: 0,
        margin: 0,
        borderRadius: 0,
    },
}));

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));

const StyledDivider = styled(Divider)(({
    backgroundColor: 'rgb(245, 245, 245, 0.5)',
    width: '100%',
    margin: '8px 0px',
}));
