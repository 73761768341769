import * as React from 'react';
import { default as moment, Moment } from 'moment';
import {
    colorMenus,
    DurationMenuType,
    formatMomentDate,
    getColorTitleByIndex,
    getDurationMenus,
    Menu,
    renderColorSelect,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../../utils';
import {
    Divider,
    Grid2 as Grid,
    Hidden,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Step as MaterialStep,
    StepButton,
    Stepper,
    Switch,
    Typography,
} from '@mui/material';
import { Button, Calendar, LabelCheckBox, NumberField, Table, TextField } from '../common';
import {
    AddOutlined,
    ChevronLeftOutlined,
    ChevronRightOutlined,
    DeleteForeverOutlined,
    DeveloperModeOutlined, ExpandMore, ListOutlined,
} from '@mui/icons-material';
import { MobileDatePicker as DatePicker, DateTimeValidationError, PickerChangeHandlerContext, MobileTimePicker as TimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/styles';
import { SelectCompensationPreDefinedItemDialog } from '../compensation';

interface Props {
    readonly promoCampaigns: Entities.PromoCampaign[];
    readonly preDefinedItems: Entities.CompensationPreDefineItem[];
    readonly onCreate: (
        name: string,
        start: number,
        end: number,
        maxRedeems: number,
        scheduleColor: string,
        developerOnly: boolean,
        codes: string[],
        rewards: Entities.PromoCampaignReward[]) => void;
}

type Step = 'Detail' | 'Schedule' | 'Confirm';
interface State {
    readonly preDefinedSelectOpen: boolean;
    readonly step: Step;
    readonly name: string;
    readonly start: Moment;
    readonly end: Moment;
    readonly maxRedeems: number;
    readonly codes: string[];
    readonly rewards: any[];
    readonly duration: number;
    readonly color: number;
    readonly timesMenus: Record<DurationMenuType, Menu>;
    readonly developerOnly: boolean;
    readonly useDuration: boolean;
}

export class CreatePromoCampaignComponent extends React.Component<Props, State> {
    state = this.initState();

    render() {
        const { step } = this.state;

        let detailContent: React.JSX.Element;
        let detailButtons: React.JSX.Element;

        let activeStep;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Schedule':
                activeStep = 1;
                detailContent = this.renderScheduleContent();
                detailButtons = this.renderScheduleButtons();
                break;
            case 'Confirm':
                activeStep = 3;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
            default:
                activeStep = -1;
                detailContent = <></>;
                detailButtons = <></>;
                break;
        }

        return (
            <>
                <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                    <Grid size={12}>
                        <Stepper
                            alternativeLabel={true}
                            nonLinear={true}
                            activeStep={activeStep}
                            style={{
                                width: '80%',
                                margin: 'auto',
                                borderRadius: 5,
                            }}
                        >
                            <MaterialStep key={'Details'} completed={activeStep > 0}>
                                <StepButton onClick={this.onDetailStepSet} >
                                    {'Details'}
                                </StepButton>
                            </MaterialStep>
                            <MaterialStep key={'Schedule'} completed={activeStep > 1}>
                                <StepButton onClick={this.onScheduleStepSet} >
                                    {'Schedule'}
                                </StepButton>
                            </MaterialStep>
                            <MaterialStep key={'Confirm'} completed={activeStep > 4}>
                                <StepButton onClick={this.onConfirmStepSet} >
                                    {'Confirm'}
                                </StepButton>
                            </MaterialStep>
                        </Stepper>
                    </Grid>
                    <Grid size={12} style={{height: 30}}/>
                    <Grid size={12}>
                        {detailContent}
                    </Grid>
                    <Grid size={12} style={{height: 30}}/>
                    <Grid size={12}>
                        <div style={{
                            display: 'grid',
                            justifyContent: 'flex-end',
                            gridAutoFlow: 'column',
                            gridColumnGap: 10,
                        }}>
                            {detailButtons}
                        </div>
                    </Grid>
                </Grid>
                {this.renderExtraDialogs()}
            </>
        );
    }

    protected renderExtraDialogs(): React.JSX.Element {
        const { preDefinedItems } = this.props;
        const { preDefinedSelectOpen } = this.state;
        return (
            <SelectCompensationPreDefinedItemDialog
                open={preDefinedSelectOpen}
                items={preDefinedItems}
                onClose={this.onClosePreDefined}
                onSelect={this.onSelectPreDefined}
            />
        );
    }

    protected initState(): State {
        const timesMenus = getDurationMenus();

        return {
            preDefinedSelectOpen: false,
            step: 'Detail',
            name: '',
            start: moment.parseZone(),
            end: moment.parseZone().add(1, 'day'),
            maxRedeems: 0,
            codes: [''],
            rewards: [{ key: '', amount: 0}],
            duration: timesMenus['1day'].value,
            color: colorMenus.BlueViolet.value,
            timesMenus,
            developerOnly: false,
            useDuration: true,
        };
    }

    protected renderDetailContent(): React.JSX.Element {
        const { name, developerOnly, maxRedeems, codes, rewards } = this.state;

        const addCode = () => {
            this.setState({codes: [...codes, '']});
            this.forceUpdate();
        };

        const addReward = () => {
            this.setState({rewards: [...rewards, { key: '', amount: 0 }]});
            this.forceUpdate();
        };

        const codesViews: React.JSX.Element[] = [];
        codes.forEach((code, index) => {
            const updateCode = (key: string) => {
                if (codes.length < index) {
                    return;
                }
                // @ts-ignore
                codes[index] = key;
                this.setState({ codes });
                this.forceUpdate();
            };
            const deleteCode = () => {
                if(codes.length < index) {
                    return;
                }
                this.setState({codes: codes.filter((item, i) => i !== index)});
                this.forceUpdate();
            };

            codesViews.push(
                <>
                    <Grid size={{ xs:12, sm:8 }}>
                        <TextField
                            fullWidth={true}
                            label=""
                            value={code}
                            variant="outlined"
                            required={true}
                            onChange={updateCode}
                        />
                    </Grid>
                    <Grid size={{ xs:12, sm:4 }}>
                        <StyledButton text="Delete" icon={DeleteForeverOutlined} variant={'text'} onClick={deleteCode} />
                    </Grid>
                </>
            );
        });

        const rewardsViews: React.JSX.Element[] = [];
        rewards.forEach((reward, index) => {
            const updateRewardKey = (key: string) => {
                if (rewards.length < index) {
                    return;
                }
                // @ts-ignore
                rewards[index].key = key;
                this.setState({ rewards });
                this.forceUpdate();
            };
            const updateRewardAmount = (amount: string) => {
                if (rewards.length < index) {
                    return;
                }
                // @ts-ignore
                rewards[index].amount = amount;
                this.setState({ rewards });
                this.forceUpdate();
            };
            const deleteReward = () => {
                if(rewards.length < index) {
                    return;
                }
                this.setState({rewards: rewards.filter((item, i) => i !== index)});
                this.forceUpdate();
            };

            rewardsViews.push(
                <>
                    {index > 0 && (
                        <Grid size={12}>
                            <StyledDivider />
                        </Grid>
                    )}
                    <Grid size={12}>
                        <InputLabel>Key</InputLabel>
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth={true}
                            label=""
                            value={reward.key}
                            variant="outlined"
                            required={true}
                            onChange={updateRewardKey}
                        />
                    </Grid>
                    <Grid size={12}>
                        <InputLabel>Amount</InputLabel>
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth={true}
                            label=""
                            value={reward.amount}
                            variant="outlined"
                            type={'number'}
                            required={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={updateRewardAmount}
                        />
                    </Grid>
                    <Grid size={{ xs:12, sm:4 }}>
                        <StyledButton text="Delete" icon={DeleteForeverOutlined} variant={'text'} onClick={deleteReward} />
                    </Grid>
                </>
            );
        });

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        label=""
                        value={name}
                        onChange={this.updateName}
                        fullWidth={true}
                        required={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Max Redeems</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        label=""
                        value={maxRedeems}
                        onChange={this.handleMaxRedeemsChange}
                        fullWidth={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <LabelCheckBox
                        label="Developer Only"
                        icon={DeveloperModeOutlined}
                        labelPlacement={'end'}
                        checked={developerOnly}
                        onChange={this.handleDeveloperOnlyChange}
                    />
                </Grid>
                <Grid size={12} style={{ margin: '10px 0px' }}>
                    <StyledDivider />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Codes</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Grid container={true} spacing={1} style={{ padding: 10 }}>
                        {codesViews}
                        <Grid size={12}>
                            <StyledButton text="Add Code" icon={AddOutlined} variant={'text'} onClick={addCode} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={12} style={{ margin: '10px 0px' }}>
                    <StyledDivider />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Rewards</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Grid container={true} spacing={1} style={{ padding: 10 }}>
                        {rewardsViews}
                        <Grid size={12}>
                            <StyledButton text="Add Reward" icon={AddOutlined} variant={'text'} onClick={addReward} />
                            <StyledButton text="Add Pre Defined" icon={ListOutlined} variant={'text'} onClick={this.openPreDefined} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={12} style={{ margin: '10px 0px' }}>
                    <StyledDivider />
                </Grid>
            </Grid>
        );
    }

    protected renderDetailButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onScheduleStepSet} variant="contained"/>
            </>
        );
    }

    protected renderScheduleContent(): React.JSX.Element {
        const { promoCampaigns } = this.props;
        const {
            name,
            start,
            end,
            duration,
            color,
            useDuration,
        } = this.state;

        const events: any[] = promoCampaigns.map(p => {
                    return {
                        id: p.id,
                        title: p.name,
                        start: new Date(p.start),
                        end: new Date(p.end),
                        backgroundColor: p.scheduleColor,
                        borderColor: p.scheduleColor,
                        textColor: 'white',
                        editable: false,
                    };
                }
            );
        events.push({
            title: name,
            start: new Date(start.valueOf()),
            end: useDuration ? new Date(start.clone().add(duration, 'minutes').valueOf()) : new Date(end.valueOf()),
            backgroundColor: getColorTitleByIndex(color),
            borderColor: 'white',
            textColor: 'white',
            editable: false,
        });

        return (
            <Grid container={true} spacing={2}>
                <Grid size={{ sm:12, md:4 }}>
                    <Grid container={true} spacing={0} style={{marginTop: 10}}>
                        {this.renderTimerCreation()}
                        <Grid size={12}>
                            <InputLabel>Color</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            {renderColorSelect(this.handleColorChange, color)}
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdDown={true}>
                    <Grid size={{ sm:12, md:8 }}>
                        <Calendar
                            headerToolbar = {{
                                left: 'dayGridMonth,timeGridWeek,timeGridDay',
                                center: 'title',
                                right: 'prevYear,prev,next,nextYear'
                            }}
                            weekends={true}
                            height={'parent'}
                            contentHeight={'auto'}
                            handleWindowResize={true}
                            events={events}
                        />
                    </Grid>
                </Hidden>
            </Grid>
        );
    }

    protected renderScheduleButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained"/>
            </>
        );
    }

    protected renderConfirmContent(): React.JSX.Element {
        const {
            name,
            start,
            end,
            codes,
            rewards,
            developerOnly,
        } = this.state;

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails style={{ backgroundColor: 'white' }}>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                {developerOnly && (
                                    <Grid size={12}>
                                        <div style={{
                                            backgroundColor: '#21BA47',
                                            color: 'white',
                                            borderColor: '#108F35',
                                            margin: 0,
                                            fontWeight: 'bold',
                                            padding: '2px 20px',
                                            display: 'inline-flex',
                                            borderRadius: '0px 12px 12px 0px',
                                            alignItems: 'center',
                                        }}
                                        >
                                            <DeveloperModeOutlined style={{ width: 18, height: 18, paddingRight: 4 }}/>
                                            Developer Only
                                        </div>
                                    </Grid>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Start at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatMomentDate(start)}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>End at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatMomentDate(end)}
                                </Grid>
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Codes
                        </StyledAccordionSummary>
                        <StyledAccordionDetails style={{ backgroundColor: 'white' }}>
                            <Grid container={true} direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                                {codes.map(code => (
                                    // tslint:disable-next-line:jsx-key
                                    <Grid justifyContent="center" alignItems="center" size={{ xs:12, sm:4, md:3 }} style={{ textAlign: 'center'}}>
                                        <strong style={{width: 250}}>{code}</strong>
                                    </Grid>
                                ))}
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Rewards
                        </StyledAccordionSummary>
                        <StyledAccordionDetails style={{ backgroundColor: 'white', padding: 0 }}>
                            <Table
                                showBox={false}
                                columns={[
                                    { title: 'Key', field: 'key'},
                                    { title: 'Amount', field: 'amount'},
                                ]}
                                options={{
                                    selection: false,
                                    paging: true,
                                    pageSize: 10,
                                    pageSizeOptions: [5, 10, 30, 50],
                                    emptyRowsWhenPaging: false,
                                    search: false,
                                    sorting: false,
                                    draggable: false,
                                    showTitle: false,
                                }}
                                data={rewards}
                            />
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
            </Grid>
        );
    }

    protected renderConfirmButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onScheduleStepSet} variant="contained" color="secondary" />
                <Button text="Confirm" onClick={this.onCreate} variant="contained"/>
            </>
        );
    }

    protected renderTimerCreation = () => {
        const { start, end, duration, timesMenus, useDuration } = this.state;
        const durationViews: React.JSX.Element[] = [];
        Object.keys(timesMenus).forEach(key => {
            const durationMenuType = key as DurationMenuType;
            const {title, value} = timesMenus[durationMenuType];
            durationViews.push(
                <MenuItem key={key} value={value}>
                    {title}
                </MenuItem>
            );
        });
        return (
            <>
                <Grid size={12}>
                    <InputLabel>Start Date</InputLabel>
                </Grid>
                <Grid size={12}>
                    <DatePicker
                        value={start}
                        onChange={this.handleStartDateChange}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Start Time</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TimePicker
                        label=""
                        value={start}
                        onChange={this.handleStartDateChange}
                        ampm={false}
                    />
                </Grid>
                {useDuration && (
                    <>
                        <Grid size={12}>
                            <InputLabel>Duration</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <Select
                                value={duration}
                                onChange={this.handleDurationChange}
                                style={{ width: '100%', backgroundColor: 'white' }}
                                variant={'outlined'}
                            >
                                {durationViews}
                            </Select>
                        </Grid>
                    </>
                )}
                {!useDuration && (
                    <>
                        <Grid size={12}>
                            <InputLabel>End Date</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <DatePicker
                                value={end}
                                onChange={this.handleEndDateChange}
                            />
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>End Time</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TimePicker
                                label=""
                                value={end}
                                onChange={this.handleEndDateChange}
                                ampm={false}
                            />
                        </Grid>
                    </>
                )}
                <Grid size={12}>
                    <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Set Time</Typography>
                        <Switch
                            checked={useDuration}
                            onChange={this.endTimeModeChange}
                            color="primary"
                            name="checkedLeaderBoardView"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography variant="caption" style={{margin: 'auto'}}>Use Duration</Typography>
                    </div>
                </Grid>
            </>
        );
    };

    protected onCreate = () => {
        const { name, start, end, duration, maxRedeems, codes, rewards, color, developerOnly, useDuration } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        this.props.onCreate(
            name,
            start.valueOf(),
            useDuration ? start.add(duration, 'minutes').valueOf() : end.valueOf(),
            maxRedeems,
            getColorTitleByIndex(color),
            developerOnly,
            codes,
            rewards,
        );
    }

    protected handleColorChange = (event: SelectChangeEvent<number>) => this.setState({ color: event.target.value as number });

    protected updateName = (name: string) => this.setState({ name });

    protected handleDeveloperOnlyChange = (developerOnly: boolean) => this.setState({ developerOnly });

    private handleMaxRedeemsChange = (maxRedeems: number) => this.setState({ maxRedeems });

    protected handleStartDateChange = (start: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (start === null) {
            return;
        }
        this.setState({ start });
    }

    protected handleEndDateChange = (end: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (end === null) {
            return;
        }
        this.setState({ end });
    }

    private handleDurationChange = (event: SelectChangeEvent<number>) => this.setState({ duration: event.target.value as number });

    protected endTimeModeChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ useDuration: event.target.checked });

    protected onDetailStepSet = () => this.setState({ step: 'Detail'});

    protected onScheduleStepSet = () => this.setState({ step: 'Schedule'});

    protected onConfirmStepSet = () => this.setState({ step: 'Confirm'});

    private openPreDefined = () => this.setState({ preDefinedSelectOpen: true });
    private onClosePreDefined = () => this.setState({ preDefinedSelectOpen: false });
    private onSelectPreDefined = (key: string, amount: number) => {
        this.setState({
            preDefinedSelectOpen: false,
            rewards: [...this.state.rewards, { key, amount }]
        });
    };
}

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&:focus': {
        backgroundColor: 'transparent',
    },
}));

const StyledDivider = styled(Divider)(({
    backgroundColor: 'rgb(224, 224, 224)',
    borderColor: 'rgb(224, 224, 224)',
}));
